import "../assets/styles/chat.css";

import React from "react";
import { useTranslation } from "react-i18next";
import Button from '@mui/material/Button';
import { logEvent } from "firebase/analytics";

import { analytics } from '../utils/firebaseAnalytics';
import LanguageSelector from "./LanguageSelector";
import {setLeftLang, setRightLang, getUserId } from "../utils/currUser";

/**
 * inperson coversation's header
 * @author Vivian
 * @date 2023-9-14
 */
function InpersonHeader({handleClear, currLeftLang, setCurrLeftLang, currRightLang, setCurrRightLang}) {

  const { t } = useTranslation();  //for showing different language text

  const handleChangeLeftLang = (lang) => {
    setCurrLeftLang(lang);  //for the interface
    setLeftLang(lang);      //for local storage
    logEvent(analytics, 'NuCHAT_Pronto_ChangeLang_Submit', {userId: getUserId().toString(), pos: 'left', lang: lang}); //record event on firebase
  }

  const handleChangeRightLang = (lang) => {
    setCurrRightLang(lang);  //for the interface
    setRightLang(lang);      //for local storage
    logEvent(analytics, 'NuCHAT_Pronto_ChangeLang_Submit', {userId: getUserId().toString(), pos: 'right', lang: lang}); //record event on firebase
  }

  const onClickClear = () => {
    handleClear();
    logEvent(analytics, 'NuCHAT_Pronto_Clear_Click', {userId: getUserId().toString()}); //record event on firebase
  }

  return (
    <div style={{ display: "flex", justifyContent: "space-between", alignItems:"center"}}>
      {/* left language selector */}
      <div id="leftLangSelector" style={{ backgroundColor: "#A24D84", borderRadius:"7px" }}>
        <LanguageSelector color= "white" currLangCode={currLeftLang} handleChangeLang={handleChangeLeftLang} />
      </div>

      {/* clear the chat history */}
      <div>
        <Button
        style={{color:"white", backgroundColor:"#501662", borderRadius:"7px"}}
          variant="contained"
          onClick={onClickClear}
        >
          {t("button.clear")}
        </Button>
      </div>

      {/* right language selector */}
      <div id="rightLangSelector" style={{ backgroundColor: "#F0b354", borderRadius:"7px" }}>
        <LanguageSelector color= "white" currLangCode={currRightLang} handleChangeLang={handleChangeRightLang} />
      </div>

    </div>
  );
}

export default InpersonHeader;
