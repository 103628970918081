import '../../assets/styles/chat.css'

import { useTranslation } from "react-i18next";
import TaskAltIcon from '@mui/icons-material/TaskAlt';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';

/**
 * showing one group status item
 * @author Vivian
 * @date 2023-11-21
 */
function StatusItem({ statusName, statusValue }) {

  const { t } = useTranslation();  //for showing different language text

  return (
      <div className="group-status-item" style={( statusValue != -1 )? { display: "block" } : { display: "none" }}>
        <div className="group-status-info">
          <div>
            <div style={{ fontWeight: 'normal'}}>
            {statusName}: 
            <span style={{fontWeight:"bold", verticalAlign:"middle"}}> 
            {
              (statusValue === true) ? < TaskAltIcon style={{color: "green", height:"12px"}}/>
              : (statusValue === false) ? < HighlightOffIcon style={{color: "red", height:"12px"}}/>
              : " " + statusValue
            }
            </span>
            </div>
          </div>
        </div>
      </div>
  );
}

export default StatusItem;
