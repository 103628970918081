import "../assets/styles/chat.css";

import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Menu, MenuItem } from "@mui/material";
import { IconButton } from "@mui/material";
import { logEvent } from "firebase/analytics";
import PersonAddAlt1Icon from '@mui/icons-material/PersonAddAlt1';
import GroupsIcon from '@mui/icons-material/Groups';
import GroupAddIcon from '@mui/icons-material/GroupAdd';

import { analytics } from "../utils/firebaseAnalytics";
import { getUserId, getAuth } from "../utils/currUser";

/**
 * the icon button for add contact (add friend, join a group or create group)
 * @author Vivian
 * @date 2023-10-30
 */
const AddContact = ({ handleAddFriend, handleCreateGroup, handleJoinGroup, setErrorCode }) => {
  const [contactAnchor, setContactAnchor] = useState(null);
  const { t } = useTranslation(); //for showing different language text
  const userAuth = getAuth();  //user auth info

  //open the add-contact operation list
  function handleIconButtonClick(event) {
    setContactAnchor(event.currentTarget);
  }

  //close the add-contact operation list
  function handleMenuClose() {
    setContactAnchor(null);
  }

  //handle click add friend
  async function onClickAddFriend() {
    //record event on firebase
    // logEvent(analytics, "open_add_friend", { userId: getUserId() });

    handleAddFriend();
    handleMenuClose();  //close the menu list
  }

  //handle click create group
  async function onClickCreateGroup() {
    //record event on firebase
    logEvent(analytics, "NuCHAT_Group_Create_Click", { userId: getUserId().toString() });

    if (!userAuth.groupPkg) {
      setErrorCode("GROUP_LIMIT_EXCEEDED");
    } else {
      handleCreateGroup();
    }

    handleMenuClose();  //close the menu list
  }

    //handle click join a group
    async function onClickJoinGroup() {
      //record event on firebase
      // logEvent(analytics, "click_join_group_menu", { userId: getUserId() });
  
      handleJoinGroup();  //deal with join a group

      handleMenuClose();  //close the menu list
    }

  return (
    <div
    >
      {/* icon button */}
      <IconButton
        color="primary"
        aria-label="send"
        component="span"
        style={{
          height: "38px",
          width: "38px",
          marginLeft: "10px",
          marginRight: "2px",
          background: "#E6B96A",
          color: "white",
          border: "1px solid #E6B96A",
        }}
        title={t("add_friend")}
        onClick={handleIconButtonClick}
      >
        <img className="add_contact_img"
          src="/images/add_contact_icon.png"
        />
      </IconButton>

      {/* operation list */}
      <Menu
        id="add-contact-menu"
        anchorEl={contactAnchor}
        keepMounted
        open={Boolean(contactAnchor)}
        onClose={handleMenuClose}
      >
        <MenuItem key="add-friend" onClick={onClickAddFriend}>
          <PersonAddAlt1Icon className="menu-icon" />{t("add_friend")}
        </MenuItem>
        <MenuItem key="join-group" onClick={onClickJoinGroup}>
          <GroupAddIcon className="menu-icon" />{t("group.join_group")}
        </MenuItem>
        <MenuItem key="create-group" onClick={onClickCreateGroup}>
          <GroupsIcon className="menu-icon" />{t("group.add_group")}
        </MenuItem>
      </Menu>
    </div>
  );
};

export default AddContact;
