import './assets/styles/chat.css';

import React, { useState, useEffect } from 'react';
import { BrowserRouter, Routes, Route, NavLink } from 'react-router-dom';
import { Toaster } from 'react-hot-toast';
import { logEvent } from "firebase/analytics";

import { analytics } from './utils/firebaseAnalytics';
import { ChatSessionsProvider } from './contexts/ChatSessionsContext'
import { getToken, getUserId } from './utils/currUser';
import UserService from './services/userService';

import PrivateRoutes from './utils/PrivateRoutes';
import PublicRoutes from './utils/PublicRoutes';
// import LanguageSelector from './components/LanguageSelector';
import Login from './pages/Login';
// import Home from './pages/Home';
import NotFound from './pages/NotFound';
import NoAuth from './pages/NoAuth';
import Loading from './pages/Loading';
import Chat from './pages/Chat';

/**
 * init user and setting routers
 * @author Vivian
 * @date 2023-8-29
 */
function App() {
  const [isLoggedIn, setIsLoggedIn] = useState(!!getToken()); // is the user login
  const [isAuthed, setIsAuthed] = useState(false); // is the user have the nuchat auth
  const [isLoading, setIsLoading] = useState(false);

  // init user - user login and get autherization
  const init = async () => {
    setIsLoading(true);
    await UserService.init(setIsLoggedIn, setIsAuthed);
    await UserService.verifyUser();
    setIsLoading(false);
    logEvent(analytics, 'NuCHAT_Start_Click', {userId: getUserId() ? getUserId().toString() : null}); //record event on firebase
  }

  useEffect(() => {
    init();
  }, []);

  // const handleLogout = () => {
  //   UserService.logout();
  // };

  return (
    <ChatSessionsProvider>
      <BrowserRouter>
        {/* <div className="header">
          <NavLink className={({ isActive }) => isActive ? 'active' : ''} to="/home">Home</NavLink>
          <NavLink className={({ isActive }) => isActive ? 'active' : ''} to="/login">Login</NavLink><small>(Access without token only)</small>
          <NavLink className={({ isActive }) => isActive ? 'active' : ''} to="/lang">Language Swithcher</NavLink>
          <NavLink className={({ isActive }) => isActive ? 'active' : ''} to="/chat">Chat</NavLink><small>(Access with token only)</small>
          <input type="button" value={t('logout')} onClick={handleLogout} />
        </div> */}

        <Toaster
          position="top-center"
          containerStyle={{ top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }}/>

        <div className="content">
          <Routes>

            {/* 404 error page */}
            <Route path="*" element={<NotFound />} />

            <Route path="/loading" element={<Loading />} />

            {/* <Route path="/home" element={<Home />} />
            <Route path="/lang" element={<LanguageSelector />} /> */}

            {/* login page */}
            <Route element={<PublicRoutes isLoggedIn={isLoggedIn} isAuthed={isAuthed} />}>
              <Route path="/login" element={<Login />} />
            </Route>

            {/* without authorize page */}
            <Route path="/noauth" element={<NoAuth />} />

            {/* not found page */}
            <Route path="/notfound" element={<NotFound />} />

            {/* default router - chat */}
            <Route element={<PrivateRoutes isLoggedIn={isLoggedIn} isAuthed={isAuthed} isLoading={isLoading} />}>
              <Route path="/" element={<Chat isLoggedIn={isLoggedIn} isAuthed={isAuthed} />} />
            </Route>

            {/* page for chat */}
            <Route element={<PrivateRoutes isLoggedIn={isLoggedIn} isAuthed={isAuthed}  isLoading={isLoading} />}>
              <Route path="/chat" element={<Chat isLoggedIn={isLoggedIn} isAuthed={isAuthed} />} />
            </Route>

          </Routes>
        </div>
      </BrowserRouter>

    </ChatSessionsProvider>

  );
}

export default App;
