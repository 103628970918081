import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getPerformance } from "firebase/performance";

// Project：nuchat-76ab0
// const firebaseConfig = {
//     apiKey: "AIzaSyD-BUmOOEZJRd5vpy8ogBv-JUUzFfRAT0o",
//     authDomain: "nuchat-76ab0.firebaseapp.com",
//     projectId: "nuchat-76ab0",
//     storageBucket: "nuchat-76ab0.appspot.com",
//     messagingSenderId: "331883164873",
//     appId: "1:331883164873:web:db1c1c7d18d41477d10918",
//     measurementId: "G-TMTER5JD61"
//   };

// Project：nu-welcom
const firebaseConfig = {
  apiKey: "AIzaSyAR4HvbpVRFUXtPWoDMU3a0tlhO79iF_EY",
  authDomain: "nu-welcom.firebaseapp.com",
  databaseURL: "https://nu-welcom.firebaseio.com",
  projectId: "nu-welcom",
  storageBucket: "nu-welcom.appspot.com",
  messagingSenderId: "1097905047913",
  appId: "1:1097905047913:web:227b38381134d4ef8055f8",
  measurementId: "G-N8TZ9YSY29"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const analytics = getAnalytics(app);
// Initialize Performance Monitoring and get a reference to the service
export const perf = getPerformance(app);
