import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-hot-toast';
import Paper from '@mui/material/Paper';
import { styled } from "@mui/material/styles";
import InputBase from '@mui/material/InputBase';
import Button from '@mui/material/Button';
import ReCAPTCHA from "react-google-recaptcha";
import { logEvent } from "firebase/analytics";

import { analytics } from '../utils/firebaseAnalytics';
import UserService from '../services/userService';
import useFormInput from '../utils/useFormInput';
import Config from "../configs/config.json";
import LanguageSelector from '../components/LanguageSelector';
import { getLang, setLang } from '../utils/currUser';
import ErrorDialog from "../utils/ErrorDialog";

const StyledButton = styled(Button)({
  //the style for disable status button
  ['&.Mui-disabled']: {
    opacity: 0.5,
  },
});

/**
 * login page
 * @author Vivian
 * @date 2023-9-1
 */
const Login = () => {

  // const history = useNavigate();
  const username = useFormInput('', 40);  //user input username
  const password = useFormInput('', 40);  //user input password
  const [captcha, setCaptcha] = useState("");
  const [error, setError] = useState(null); //for showing the error text
  const [loading, setLoading] = useState(false);
  const [errorCode, setErrorCode] = useState(null); //set the error code for error dialog
  const { t } = useTranslation(); //for showing different language text

  const [key, setKey] = useState(0);

  const refreshCaptcha = () => {
    setKey((prevKey) => prevKey + 1);
  };
  // handle button click of login form
  const handleLogin = async () => {
    setError(null);
    setLoading(true);
    const result = await UserService.login(username.value, password.value, captcha, Config.captcha.enabled );
    setLoading(false);

    if (!result.success) {
      // toast.error(t(result.message));
      setErrorCode(result.message);
      refreshCaptcha();
    }
    //record event on firebase
    // logEvent(analytics, 'user_login', { username: username.value });
  }

  const handleCaptchaChange = (newToken) => {
    setCaptcha(newToken);
  };

  // handle button click of login form
  const handleRegister = async () => {
    window.open(Config.website.signUpUrl, '_blank');
  }

  //handle user select a language
  const handleChangeLang = (langCode) => {
    //record event on firebase
    // logEvent(analytics, 'change_language', { currLang: langCode });
    setLang(langCode);
  }

  return (
    <div className='login-page'>
      {/* language selector */}
      <div style={{ position: "fixed", top: "20px", right: "20px" }}>
        <LanguageSelector color="purple" currLangCode={getLang()} handleChangeLang={handleChangeLang} />
      </div>

      {/* logo */}
      <img className="login-logo"
        src="/images/nuwelcom_logo.png"
        alt='Nuwelcom'
      />

      {/* username */}
      <Paper className='paper-with-shadow'>
        <InputBase type="text" className='login-input' 
        value={username.value} 
        onChange={username.onChange} 
        onKeyDown={username.onKeyDown}
        // {...username}
         placeholder={t('email')} />
      </Paper>

      {/* password */}
      <Paper className='paper-with-shadow'>
        <InputBase type="password" className='login-input' 
        value={password.value} 
        onChange={password.onChange} 
        onKeyDown={password.onKeyDown}
        // {...password}
         placeholder={t('password')} />
      </Paper>

      {/* error information */}
      {error && <><small style={{ color: 'red' }}>{error}</small><br /></>}<br />

      {/* captcha */}
      {Config.captcha.enabled ? (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <ReCAPTCHA
            sitekey={Config.captcha.captchaKey}
            onChange={handleCaptchaChange}
            key={key}
          />
        </div>
      ) : null}

      {/* forgot password and sign up */}
      <div style={{ display: "flex", flexDirection: "row", justifyContent: "center" }}>
        <div style={{ margin: "20px 25px 10px 0px", fontWeight: "bold", color: "#501662" }}>{t('not_member')}</div>
        <div style={{ margin: "20px 0px 10px 25px", fontWeight: "bold" }}>
          <a href={Config.website.forgotPasswordUrl} target="_blank" style={{ color: "#501662", textDecoration: "none" }}>{t('forgot_password')}</a>
        </div>
      </div>

      {/*buttons*/}
      <div style={{ display: "flex", flexDirection: "row" }}>
        {/* sign up button */}
        <StyledButton
          style={{ color: "white", fontWeight: "bold", backgroundColor: "#F0b354", borderRadius: 7, width: "20vh", height: "6vh", marginRight: "15px" }}
          variant="contained"
          color="primary"
          onClick={handleRegister}
        >
          {t('register')}
        </StyledButton>

        {/* login button */}
        <StyledButton
          style={{ color: "white", fontWeight: "bold", backgroundColor: "#501662", borderRadius: 7, width: "20vh", height: "6vh", marginLeft: "15px" }}
          disabled={Config.captcha.enabled ? (!captcha || loading) : false}
          variant="contained"
          color="primary"
          value={loading ? t('loading') : t('login')}
          onClick={handleLogin}
        >
          {t('login')}
        </StyledButton>

        {/* error dialog */}
        <ErrorDialog errorCode={errorCode} setErrorCode={setErrorCode}/>

      </div>
    </div>
  );
}

export default Login;

