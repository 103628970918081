import React, { useState, useEffect } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import toast from "react-hot-toast";
import { useTranslation } from "react-i18next";
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import { logEvent } from "firebase/analytics";

import { analytics } from "./firebaseAnalytics";
import { getUserId, getSettings } from './currUser';
import Config from "../configs/config.json";

/**
 * The component is used to pop up a error dialog
 * @param errorCode the error code
 * @author Vivian
 * @date 2023-11-20
 */

export default function ErrorDialog({errorCode, setErrorCode}) {

  const [title, setTitle] = useState(""); //dialog title
  const [content, setContent] = useState(""); //content of the dialog, to notice the user
  const [showBuyButton, setShowBuyButton] = useState(false); //if showing the "buy now" button
  const [openDialog, setOpenDialog] = useState(false);
  const { t } = useTranslation(); //for showing different language text

  useEffect(() => {
    if(!errorCode || errorCode === ""){
      setOpenDialog(false);
    } else if(errorCode === "PRODUCT_AUTH_ERROR" || errorCode === "GROUP_LIMIT_EXCEEDED"
    || errorCode === "GROUP_MEMBER_LIMIT_EXCEEDED" || errorCode === "FEATURE_AUTH_ERROR"){
      //error dialog with both "buy now" button and "cancel" botton
      setTitle(t("error_dialog.title"));
      setContent(t(errorCode+".WEB.INFO"));
      setShowBuyButton(true);
      setOpenDialog(true);
    } else if(errorCode === "GROUP_CHAR_LIMIT_EXCEEDED" || errorCode === "SESSION_STATUS_ERROR"){
      //error dialog with only "cancel" button
      setTitle(t("error_dialog.title"));
      setContent(t(errorCode));
      setShowBuyButton(false);
      setOpenDialog(true);
    } else {
      //toast
      setOpenDialog(false);
      toast.error(t(errorCode));
      setErrorCode("");
    }
}, [errorCode]);

  // handle "buy now" button click
  const handleClickBuy = async () => {
    logEvent(analytics, 'NuCHAT_ByNow_Click', {userId: getUserId().toString()}); //record event on firebase
    window.open(getSettings().paymentUrl, '_blank');
  }

  //Close the dialog box
  const handleClose = () => {
    setOpenDialog(false);
    setErrorCode("");
  };

  return (
    <Dialog
      style={{width:"100%"}}
      open={openDialog}
      onClose={handleClose}
    >
      <DialogTitle id="message-panel-header" style={{paddingLeft: 20}}>
        {title}
      </DialogTitle>
      <DialogContent sx={{ width: '400px' }}>
        <DialogContentText style={{paddingTop:20}}>
          {content}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button style={{fontWeight:"bold", color:"#513695", marginBottom: "10px", marginRight: "15px"}} onClick={handleClose} autoFocus>
        {t("button.close")}
        </Button>
        {showBuyButton? <Button style={{fontWeight:"bold", backgroundColor:"#f0dbf2", color:"#513695", marginBottom: "10px", marginLeft:"0px", marginRight: "15px"}} onClick={handleClickBuy}>
        <ShoppingCartIcon className="menu-icon" /> {t("payment.link.button")}
        </Button> : null}
      </DialogActions>
    </Dialog>
  );
}
