import { createContext, useContext, useReducer } from 'react';

import { getCurrSessionId } from '../utils/currUser';

/**
 * chat session list context - for sharing the information with other components
 * @author Vivian
 * @date 2023-9-6
 */
const ChatSessionsContext = createContext(null);  //chat session list
const ChatSessionsDispatchContext = createContext(null); // for edit chat session list

export function ChatSessionsProvider({ children }) {
  const [chatSessions, dispatch] = useReducer(
    chatSessionsReducer,
    initialChatSessions
  );

  return (
    <ChatSessionsContext.Provider value={chatSessions}>
      <ChatSessionsDispatchContext.Provider value={dispatch}>
        {children}
      </ChatSessionsDispatchContext.Provider>
    </ChatSessionsContext.Provider>
  );
}

export function useChatSessions() {
  return useContext(ChatSessionsContext);
}

export function useChatSessionsDispatch() {
  return useContext(ChatSessionsDispatchContext);
}

function chatSessionsReducer(chatSessions, action) {
  switch (action.type) {

    // init chat sessions data
    case 'init': {
      return action.data;
    }

    // friend online or offline
    case 'friendOnline': {
      let isOnline = false;
      if (action.status == "online") {
        isOnline = true;
      }
      if (chatSessions) {
        return chatSessions.map((chatSession) => {
          if (action.userIds.includes(chatSession.friendId) && chatSession.deletedAt === null) {
            return { ...chatSession, isOnline: isOnline };
          } else {
            return chatSession;
          }
        });
      } else {
        return chatSessions;
      }
    }

    // Add a new friend || create a new group || join a group
    case 'sessionAdded': {
      //remove the old session info if it exists
      let cs = chatSessions.filter(chatSession => (chatSession._id !== action.data._id && 
        chatSession.friendId !== action.data.friendId
      )); 
      return [action.data, ...cs];
    }

    case 'sessionDeleted': {
      if (action.isRemove) {
        //remove this session
        return chatSessions.filter(chatSession => chatSession._id !== action.session._id);
      } else {
        //mark this session as deleted
        let cs = chatSessions.map((chatSession) => {
          if (chatSession._id == action.session._id) {
            //set current chat session
            return { ...chatSession, deletedAt: action.session.deletedAt, isOnline: false };
          } else {
            return chatSession;
          }
        });
        return cs;
      }
    }

    // user change the current session
    case 'changeCurrSession': {
      return chatSessions.map((chatSession) => {
        const messagesLength = chatSession.messages.length;

        if (chatSession._id == action.sessionId) {
          //set current chat session
          return { ...chatSession, hasNewMessage: false, isCurrSession: true };
        } else if (chatSession.isCurrSession && messagesLength > 0) {
          //update the old current session's as read all the messages
          const lastMessageId = chatSession.messages[messagesLength - 1]._id;
          const lastMessageAt = chatSession.messages[messagesLength - 1].createdAt;
          return { ...chatSession, isCurrSession: false, lastSeenMessageId: lastMessageId, lastSeenMessageAt: lastMessageAt };
        } else {
          //set all the other chat session as not current session
          return { ...chatSession, isCurrSession: false };
        }
      });
    }

    //received a new message
    case 'msgReceived': {
      const updatedChatSessions = chatSessions.map((chatSession) => {
        if (chatSession._id === action.data.sessionId) {
          const addedMsgs = [...chatSession.messages, action.data];
          const updatedSession = {
            ...chatSession,
            messages: addedMsgs,
            lastMessageId: action.data._id,
            lastMessageCreatedAt: action.data.createdAt,
            ...(chatSession.isCurrSession ? {
              hasNewMessage: false,
              lastSeenMessageId: action.data._id,
              lastSeenMessageAt: action.data.createdAt,
            } : {
              hasNewMessage: true,
            }),
          };
          return updatedSession;
        } else {
          return chatSession;
        }
      });
    
      // Find the updated session and move it to the front
      const sessionIndex = updatedChatSessions.findIndex( // Find the index of the updated session
        (session) => session._id === action.data.sessionId
      );
      if (sessionIndex !== -1) {
        const [updatedSession] = updatedChatSessions.splice(sessionIndex, 1); // Remove the updated session
        updatedChatSessions.unshift(updatedSession);  // Add the updated session to the front
      }
    
      return updatedChatSessions;
    }

    //received a updated message
    case 'msgUpdated': {
      return chatSessions.map((chatSession) => {
        if (chatSession._id === action.data.sessionId) {
          const updatedMsg = chatSession.messages.map(message => {
            if (message._id === action.data._id) {
              return action.data;
            } else {
              return message;
            }
          })
          // if (getCurrSessionId() == chatSession._id) {
          //   action.setCurrMsgs(updatedMsg);
          // }
          return {
            ...chatSession,
            messages: updatedMsg,
          };
        } else {
          return chatSession;
        }
      });
    }

    //mark messages read
    case 'msgRead': {
      if (chatSessions) {
        return chatSessions.map((chatSession) => {
          if (chatSession._id === action.data._id) {
            return {
              ...chatSession,
              hasNewMessage: false,
            };
          } else {
            return chatSession;
          }
        });
      } else {
        return chatSessions;
      }

    }

    //add group member
    case 'groupMemberAdded': {
      let cs = chatSessions.map((chatSession) => {
        if (chatSession._id === action.session._id) {
          const updatedSession = {
            ...chatSession,
            members: action.session.members,
            membersDetail: [...chatSession.membersDetail, ...action.session.membersDetail]
          }
          return updatedSession;
        } else {
          return chatSession;
        }
      });
      return cs;
    }

    //remove group member
    case 'groupMemberRemoved': {
      let cs = chatSessions.map((chatSession) => {
        if (chatSession._id === action.session._id) {
          const updatedMembers = chatSession.membersDetail.map(member => {
            if (member.memberId === action.session.membersDetail[0].memberId) {
              return { ...member, deletedAt: action.session.membersDetail[0].deletedAt };
            } else {
              return member;
            }
          })
          return {
            ...chatSession,
            members: action.session.members,
            membersDetail: updatedMembers,
          };
        } else {
          return chatSession;
        }
      });
      return cs;
    }

    case 'groupUpdated': {
      let cs = chatSessions.map((chatSession) => {
        if (chatSession._id == action.session._id) {
          //set current chat session
          return { ...chatSession, groupName: action.session.groupName, groupDesc: action.session.groupDesc, admins: action.session.admins};
        } else {
          return chatSession;
        }
      });
      return cs;
    }

    case 'groupAvatarUpdated': {
      let cs = chatSessions.map((chatSession) => {
        if (chatSession._id == action.groupId) {
          //set current chat session
          return { ...chatSession, groupAvatar: action.groupAvatar };
        } else {
          return chatSession;
        }
      });
      return cs;
    }

    case 'logout': {
      return null;
    }

  }
}

const initialChatSessions = [];