import { createTheme } from '@mui/material/styles';

const customTheme = createTheme({
  components: {
    MuiTextField: {
      styleOverrides: {
        root: {
          '& .MuiOutlinedInput-root': {
            '& fieldset': {
              color: 'purple',
              borderColor: 'purple',  //outline border color
            },
            '&:hover fieldset': {
              color: 'purple',
              borderColor: 'purple',  //outline border color
            },
            '&.Mui-focused fieldset': {
              color: 'purple',
              borderColor: 'purple',  //outline border color
            },
          },
          '& label': {
            color: 'purple',
          },
          "& label.Mui-focused": {
            color: 'purple',
          },
          '& .MuiInputLabel-root': {  // label color
            color: 'gray',
          },
          '&:hover .MuiInputLabel-root': {  // label color
            color: 'purple',
          },
          '&.Mui-focused .MuiInputLabel-root': {  // label color
            color: 'purple',
          },
          // '& .MuiInputBase-input::placeholder': {
          //   color: 'gray', // 默认 placeholder 颜色
          // },
          // '&:hover .MuiInputBase-input::placeholder': {
          //   color: 'purple', // 默认 label 颜色
          // },
          // '&.Mui-focused .MuiInputBase-input::placeholder': {
          //   color: 'purple', // 获取焦点时的 placeholder 颜色
          // },
        },
      },
    },
  },
});

export default customTheme;