import "../../assets/styles/chat.css"

import React, { useState, useEffect } from 'react';
import { useTranslation } from "react-i18next";
import { logEvent } from "firebase/analytics";
// import { TextField } from "@mui/material";
// import Button from "@mui/material/Button";
import { useForm, Controller } from 'react-hook-form';
// import { ThemeProvider } from '@mui/material/styles';
// import DialogActions from "@mui/material/DialogActions";
// import DialogContent from "@mui/material/DialogContent";

import { analytics } from "../../utils/firebaseAnalytics";
import { getSocket } from "../../socket";
import DialogWindowTemplate from "../../utils/DialogWindowTemplate";
// import customTheme from "../../utils/customTheme";
import { getUserId } from '../../utils/currUser';
import SearchUserBar from "../SearchUserBar";
import GroupItem from "./GroupItem";
import UserItem from "../UserItem";
import { getQRCodeInfo } from "../../services/nuchatApi";

/**
 * create group dialog
 * @param {function} props.onClose handle close the dialog
 * @param {Boolean} props.open the dialog is open?
 * @author Vivian
 * @date 2023-11-30
 */
export default function JoinGroupDialog(props) {
  const socket = getSocket();
  const { t } = useTranslation();  //for showing different language text
  const { handleSubmit, control, setError, clearErrors, setValue, formState: { errors } } = useForm();
  const [resultType, setResultType] = useState('group'); //result type: 'group' or 'user'
  const [resultList, setResultList] = useState([]);

  useEffect(() => {
    clearErrors('groupCode');
    setValue('groupCode', '');
    setResultType('group');
    setResultList([]);
  }, [props.open]);

  // const onSubmit = async (data) => {
  //   if (!data.groupCode) {
  //     setError('groupCode', { type: 'manual', message: t('group.code.required') });
  //     return;
  //   }

  //   logEvent(analytics, "NuCHAT_Group_Join_Submit", { userId: getUserId().toString()}); //record event on firebase
  //   socket.emit("scan_qrcode", data.groupCode);
  // };

  const handleSearch = async (qrCode) => {
    if (!qrCode) {
      return;
    }
    logEvent(analytics, "NuCHAT_SearchGroup_Submit", { userId: getUserId().toString() });//record event on firebase
    const result = await getQRCodeInfo(qrCode);
    if (result.data) {
      setResultType(result.data.type)
      setResultList([result.data.info]);
    } else {
      setResultType('group');
      setResultList([]);
    }
  };

  const onClose = () => {
    clearErrors('groupCode');
    setValue('groupCode', '');
    props.onClose();
  }

  return (
    <DialogWindowTemplate
      title={t("group.join_group")}
      onClose={onClose}
      open={props.open}
    >
      <div>
        {/* Search Bar */}
        <div className="dialog-input">
          <SearchUserBar
            placeholderText={t("group.code")}
            handleSearch={handleSearch}
          ></SearchUserBar>
        </div>

        {/*
        <form onSubmit={handleSubmit(onSubmit)}>

          <DialogContent>
            <div>
              <Controller
                name="groupCode"
                control={control}
                rules={{ required: t('group.code.required') }}
                render={({ field }) => (

                  <ThemeProvider theme={customTheme}>
                    <TextField
                      {...field}
                      label={t('group.code')}
                      variant="outlined"
                      error={Boolean(errors.groupCode)}
                      helperText={errors.groupCode?.message}
                      style={{ width: '300px' }}
                      autoFocus
                    />
                  </ThemeProvider>

                )}
              />
            </div>
          </DialogContent>
          <DialogActions>
            <Button style={{ fontWeight: "bolder", color: "#513695" }} onClick={onClose}>
              {t('button.cancel')}
            </Button>
            <Button type="submit" style={{ fontWeight: "bolder", backgroundColor: "#f0dbf2", color: "#513695" }} >
              {t('group.join_group.submit')}
            </Button>
          </DialogActions>
        </form> */}
      </div>
      {resultType === 'group' ?
        // group list
        <div className="dialog-row">
          <div id="user-list" style={{ width: "45vh", padding: "10px" }}>
            {resultList.map((group) => {
              return (
                <GroupItem
                  socket={socket}
                  group={group}
                  key={group.id}
                  isShowDeleteIcon={false}
                />
              );
            })}
          </div>
        </div>
        :
        // user list
        <div className="dialog-row">
          <div id="user-list" style={{ width: "45vh", padding: "10px" }}>
            {resultList.map((user) => {
              return (
                <UserItem
                  socket={socket}
                  user={user}
                  key={user.id}
                  isShowDeleteIcon={false}
                />
              );
            })}
          </div>
        </div>}
    </DialogWindowTemplate>
  );
}
