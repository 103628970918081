import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

/**
 * The component is used to pop up a confirm dialog box, it also can be used to display the content that need the user to choose yes or no.
 * @param title is the dialog box title
 * @param notice is the content of the dialog box, to notice the user.
 * @param cancelButtonName is the name for the cancel button
 * @param confirmButtonName is the name for the confirm button
 * @param openDialog is the default state of the dialog box, display or not.
 * @param handleClose is a function to close this dialog 
 * @param handleConfirm is a function need run after user click the action button
 * @author Yongsheng
 * @date 2023-10-30
 */

export default function ConfirmDialog({title, notice, cancelButtonName, confirmButtonName, openDialog, handleClose, handleConfirm}) {
  return (
    <Dialog
      open={openDialog}
      onClose={handleClose}
    >
      <DialogTitle id="message-panel-header" style={{paddingLeft: 20}}>
        {title}
      </DialogTitle>
      <DialogContent>
        <DialogContentText style={{paddingTop:20}}>
          {notice}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button style={{ fontWeight: "bold", color: "#513695", marginBottom: "10px", marginRight: "15px" }} onClick={handleClose} autoFocus>
          {cancelButtonName}
        </Button>
        <Button style={{ fontWeight: "bold", backgroundColor: "#f0dbf2", color: "#513695", marginBottom: "10px", marginLeft:"0px", marginRight: "15px" }} onClick={handleConfirm}>
          {confirmButtonName}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
