import "../../assets/styles/chat.css"

import React, { useEffect, useState, useRef } from 'react';
import ReactLoading from 'react-loading';
import { useTranslation } from "react-i18next";
import { logEvent } from "firebase/analytics";
import IconButton from '@mui/material/IconButton';
import Button from "@mui/material/Button";
import { toast } from 'react-hot-toast';
import DeleteIcon from '@mui/icons-material/Delete';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import { Avatar } from "@mui/material";
import DriveFileRenameOutlineIcon from '@mui/icons-material/DriveFileRenameOutline';
import QrCodeIcon from '@mui/icons-material/QrCode';

import { analytics } from "../../utils/firebaseAnalytics";
import Config from "../../configs/config.json";
import { getSocket } from "../../socket";
import { getUserId, getSettings } from '../../utils/currUser'
import DialogWindowTemplate from "../../utils/DialogWindowTemplate";
import FilterBar from "../FilterBar";
import MemberItem from "./MemberItem";
import StatusItem from "./StatusItem";
import ConfirmDialog from "../../utils/ConfirmDialog";
import ChatService from '../../services/chatService';
import configUrl from "../../configs/config.json";
import { useChatSessionsDispatch } from '../../contexts/ChatSessionsContext';
import QRCodeDialog from "../QRCodeDialog";

/**
 * group detail dialog
 * @param {function} props.onClose handle close the dialog
 * @param {Boolean} props.open the dialog is open?
 * @param {Session} props.chatSession the session detail
 * @param {function} props.handleOpenAddMemberDialog handle open the add group mumber dialog
 * @param {function} props.handleOpenUpdateGroupNameDialog handle open the update group dialog
 * @param {function} props.handleOpenUpdateGroupDescDialog handle open the update group dialog
 * @author Vivian
 * @date 2023-11-21
 */
export default function GroupDetailDialog(props) {

  const chatSessionsDispatch = useChatSessionsDispatch();  // for edit chatSessions
  const [memberFilter, setMemberFilter] = useState(""); //search member text
  const [isAdmin, setIsAdmin] = useState(false); //is current user admin of this group?
  const memberListRef = useRef(null);  //for auto scroll the member list to bottom
  const [oldMemberNum, setOldMemberNum] = useState(props.chatSession.membersDetail.length); //members number for scroll the member list after add new members
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false); //is the confirm dialog open
  const [confirmDialogProps, setConfirmDialogProps] = useState({});    //settings for confirm dialog
  const [availableChars, setAvailableChars] = useState(props.chatSession.groupAvailableChars); //group available chars
  const [isLoading, setIsLoading] = useState(false);
  const [showQRCode, setShowQRCode] = useState(false); //This state is for QR code dialog box
  const userId = getUserId();   //current user id
  const socket = getSocket();
  const { t } = useTranslation();  //for showing different language text

  useEffect(() => {
    setIsAdmin(props.chatSession.admins.includes(userId));
  }, [props.chatSession]);

  const getAvailableChars = async () => {
    const chars = await ChatService.getGroupAvailableChars(props.chatSession._id);
    setAvailableChars(chars);
  }

  useEffect(() => {
    if (props.open && isAdmin) {
      getAvailableChars();
    }
  }, [props.open]);

  // when add a new member, scroll the message list to the bottom
  useEffect(() => {
    const newMemberNum = props.chatSession.membersDetail.length;
    if (newMemberNum > oldMemberNum) {
      scrollToBottom();
    }
    setOldMemberNum(props.chatSession.membersDetail.length)
  }, [props.chatSession.membersDetail.length]);

  //scroll the message list to the bottom
  const scrollToBottom = () => {
    if (memberListRef.current) {
      memberListRef.current.scrollTop = memberListRef.current.scrollHeight;
    }
  };

  //handle submit the message
  async function onClickAddMember() {
    if (isAdmin) {
      props.handleOpenAddMemberDialog();
    } else {
      toast.error(t("OPERATION_AUTH_ERROR"));
    }
  };

  //delete group
  function handleDeleteGroup() {
    setOpenConfirmDialog(false);  //close the confirm dialog

    //record event on firebase
    // logEvent(analytics, "NuCHAT_Group_Delete_Submit", { userId: getUserId().toString(), sessId: props.chatSession._id });

    //send delete group request
    socket.emit("delete_session", props.chatSession._id);
  }

  //leave group
  function handleLeaveGroup() {
    setOpenConfirmDialog(false);  //close the confirm dialog

    //record event on firebase
    // logEvent(analytics, "NuCHAT_Group_Leave_Submit", { userId: getUserId().toString(), sessId: props.chatSession._id });

    //send remove member request
    socket.emit("remove_group_member", { groupId: props.chatSession._id, memberId: getUserId() });
  }

  //open delete group confirm dialog
  function onClickDeleteGroup() {
    setOpenConfirmDialog(true);
    logEvent(analytics, "NuCHAT_Group_Delete_Click", { userId: getUserId().toString(), sessId: props.chatSession._id });

    setConfirmDialogProps({
      title: t("group.delete_group.confirm_dialog.title"),
      notice: t("delete_session.confirm_dialog.text", { friendName: props.chatSession.groupName }),
      cancelButtonName: t("delete_session.confirm_dialog.cancel"),
      confirmButtonName: t("delete_session.confirm_dialog.confirm"),
      handleConfirm: handleDeleteGroup,
    });
  }

  //open leave group confirm dialog
  function onClickLeaveGroup() {
    setOpenConfirmDialog(true);

    //record event on firebase
    logEvent(analytics, "NuCHAT_Group_Leave_Click", { userId: getUserId().toString(), sessId: props.chatSession._id });

    setConfirmDialogProps({
      title: t("group.leave_group"),
      notice: t("group.leave_group.confirm_dialog.text", { groupName: props.chatSession.groupName }),
      cancelButtonName: t("group.leave_group.confirm_dialog.cancel"),
      confirmButtonName: t("group.leave_group.confirm_dialog.confirm"),
      handleConfirm: handleLeaveGroup,
    });
  }

  //Close the confirm dialog
  const handleConfirmDialogClose = () => {
    setOpenConfirmDialog(false);
  };

  // handle buy button click
  const handleBuyClick = async () => {
    logEvent(analytics, 'NuCHAT_ByNow_Click', { userId: getUserId().toString() }); //record event on firebase
    window.open(getSettings().paymentUrl, '_blank');
  }

  // handle upload avatar button click
  function handleUploadAvatar() {
    const input = document.createElement('input');
    input.type = 'file';
    input.accept = 'image/jpeg, image/png';
    input.onchange = async (e) => {
      const file = e.target.files[0];
      if (file) {
        setIsLoading(true);
        try {
          let res = await ChatService.uploadGroupAvatar(props.chatSession._id, file)
          if (res) {
            await chatSessionsDispatch({
              type: 'groupAvatarUpdated',
              groupId: props.chatSession._id,
              groupAvatar: configUrl.api.nuchatUrl + "/" + res,
            });
          }
        } catch (error) {
          toast.error(t("upload_file.error"));
        } finally {
          setIsLoading(false);
        }
      }
    };
    input.click();
  }

  //close the "QR Code" dialog
  const handleQRCodeClose = () => {
    setShowQRCode(false);
  };

  //handle click QR code
  async function handleShowQRCode() {
    //record event on firebase
    logEvent(analytics, "NUWELCOM_QRCode_Group_show", { sessId: props.chatSession._id });

    setShowQRCode(true);  //open the QRcode dialog box
  }
  return (
    <DialogWindowTemplate
      title={t('group.menu.detail')}//{`${props.chatSession.groupName}`} ( ${props.chatSession.members.length} )`}
      onClose={props.onClose}
      open={props.open}
      showEditTitle={false}
      onClickEditTitle={props.handleOpenUpdateGroupNameDialog}
      style={{ width: "50vh" }}
    >
      {isLoading ?
        <div style={{ position: "fixed", top: 0, left: 0, width: "100%", height: "100%", display: "flex", justifyContent: "center", alignItems: "center", zIndex: "999" }}>
          <ReactLoading type="spinningBubbles" color="purple" width={100} height={100} />
        </div> : null
      }
      {/* group avatar */}
      <div className="dialog-row">
        <div className="avatar-container" style={{ position: 'relative', display: 'inline-block' }} onClick={handleUploadAvatar}>
          <Avatar
            style={{
              backgroundColor: "#8F3E97",
              color: "rgb(255, 255, 255, 0.8)",
              height: "90px",
              width: "90px",
              marginTop: "5px",
              marginBottom: "15px",
            }}
            src={props.chatSession.groupAvatar ? props.chatSession.groupAvatar : "n/a"}
            alt={props.chatSession.groupName}
          />
          <img src='../images/camera.png' style={{ position: "absolute", right: 0, bottom: 25, width: "30%" }} />
        </div>
      </div>
      {/* group name */}
      <div className="dialog-row">
        
        <div style={{ marginBottom: "0px" }}>{props.chatSession.groupName}</div>
        <div onClick={handleShowQRCode} style={{paddingLeft: "10px"}}><QrCodeIcon className="menu-icon" style={{color: "rgb(81, 54, 149)"}}/></div>
        {isAdmin ? <DriveFileRenameOutlineIcon style={{ color: "rgb(81, 54, 149)" }} onClick={props.handleOpenUpdateGroupNameDialog} /> : null}
      </div>
      {/* group description */}
      <div className="dialog-row">
        <div style={{ width: isAdmin ? '39vh' : '43vh', margin: "15px", fontWeight: 'normal', fontSize: 12, color: "gray", textAlign: "center" }}>
          {props.chatSession.groupDesc ? props.chatSession.groupDesc : t("group.description")}
        </div>
        {isAdmin ? <DriveFileRenameOutlineIcon style={{ paddingLeft: "15px", color: "rgb(81, 54, 149)" }} onClick={props.handleOpenUpdateGroupDescDialog} /> : null}
      </div>
      <div className="dialog-row">
        {/* search input */}
        <div className={isAdmin ? "dialog-input-admin" : "dialog-input"}>
          <FilterBar placeholderText={t("search_box.search")} setFilter={(setMemberFilter)}></FilterBar>
        </div>
        {/* add group member icon button */}
        {isAdmin ? <IconButton
          color="primary"
          aria-label={t("group.add_member")}
          component="span"
          style={{
            height: "38px",
            width: "38px",
            marginLeft: "10px",
            background: isAdmin ? "#E6B96A" : "#cccccc",
            color: "white",
            // border: "1px solid #E6B96A",
          }}
          title={t("group.add_member")}
          onClick={onClickAddMember}
        >
          <img className="add_contact_img"
            src="/images/add_contact_icon.png"
          />
        </IconButton> : null}
      </div>

      {/* member list */}
      <div className="dialog-row">
        <div id="group-member-list" style={{ width: "45vh", padding: "10px" }} ref={memberListRef}>
          {props.chatSession.membersDetail.map((member) => {
            return (
              <MemberItem
                socket={socket}
                member={member}
                memberFilter={memberFilter}
                admins={props.chatSession.admins}
                isCurrUserAdmin={isAdmin}
                key={member._id}
              />
            );
          })}
        </div>
      </div>

      {/* group status - only for group admin */}
      <div className="dialog-row">
        {isAdmin ? <div id="group-status-list">
          <StatusItem statusName={t("group.group_status")} statusValue={props.chatSession.groupStatus === "active" ? -1 : props.chatSession.groupStatus} />
          <StatusItem statusName={t("group.available_chars")} statusValue={availableChars} />
          <StatusItem statusName={t("group.max_member")} statusValue={props.chatSession.groupMaxMember} />
          <StatusItem statusName={t("group.valid_until")} statusValue={props.chatSession.groupValidUntil === null ? -1 : new Date(props.chatSession.groupValidUntil).toLocaleDateString()} />

          {/*"buy now" button*/}
          <Button style={{ fontWeight: "bolder", color: "#513695", padding: "0", fontSize: "12px" }} onClick={handleBuyClick} >
            <ShoppingCartIcon className="menu-icon" style={{ width: "16px" }} /> {t("payment.link.button")}
          </Button>
        </div>
          : null}
      </div>

      {/* buttons */}
      {/* leave group button */}
      <div className="dialog-row">
      <Button
            style={{ color: "white", marginTop: "25px", marginBottom: "15px", width: "70%", backgroundColor: "#Af4687", borderRadius: 7 }}
            variant="contained"
            color="primary"
            onClick={onClickLeaveGroup}
          >
            <DeleteIcon className="menu-icon" />{t("group.leave_group")}
          </Button>
      </div>
      {/* delete group button (only for group admin) */}
      <div className="dialog-row">
        {isAdmin ? <Button
          style={{ color: "white", marginBottom: "20px", width: "70%", backgroundColor: "#Af4687", borderRadius: 7 }}
          variant="contained"
          color="primary"
          onClick={onClickDeleteGroup}
        >
          <DeleteIcon className="menu-icon" />{t("group.delete_group")}
        </Button>
          : null }
      </div>

      {/* Open dialog box to confirm if delete the group or leave the group */}
      <ConfirmDialog
        openDialog={openConfirmDialog}
        handleClose={handleConfirmDialogClose}
        {...confirmDialogProps}
      ></ConfirmDialog>

      {/* QR code dialog*/}
      <QRCodeDialog
        QRCodeStr={props.chatSession.groupQRCode}
        onClose={handleQRCodeClose}
        open={showQRCode}
        title={props.chatSession.groupName}
        subTitle={isAdmin ? props.chatSession.groupQRCode : null}
      />
    </DialogWindowTemplate>
  );
}
