
import { service } from '../utils/request'

/**
 * Login
 * @param {Object} params {string email, string password, string lang}
 * @author Vivian
 * @Date 2023-8-28
 */
export async function loginApi(params) {
  console.log("[NUCHATAPI] login");
  return service({
    url: `/login`,
    method: 'post',
    data: params
  });
}

/**
 * Get user nuchat authorization by token
 * @param {Object} params {string lang}
 * @author Vivian
 * @Date 2023-8-28
 */
export async function getNuChatAuthApi(params) {
  console.log("[NUCHATAPI] getNuChatAuth");
  return service({
    url: `/getAuth`,
    method: 'get',
    params
  });
}

/**
 * Get all user chat sessions
 * @author Vivian
 * @Date 2023-8-31
 */
export async function getAllSessionsApi() {
  console.log("[NUCHATAPI] getAllSessions");
  // const params = {
  //   deviceType: "web",
  // };
  return service({
    url: `/session`,
    method: 'get',
    // params
  });
}

/**
 * Get all messages by chat session id
 * @author Vivian
 * @Date 2023-8-31
 */
export async function getAllMsgBySessIdApi(sessionId) {
  console.log("[NUCHATAPI] getAllMessagesBySessionId");
  //only can get 30 days history
  let createdAt = new Date();
  createdAt.setDate(createdAt.getDate() - 30);
  const params = {
    createdSince: createdAt,
  };
  return service({
    url: `/session/messages/${sessionId}`,
    method: 'get',
    params
  });
}

/**
 * Get session basic info
 * @param {string} sessionId session id
 * @author Vivian
 * @Date 2023-11-29
 */
export async function getSessionBasicInfoApi(sessionId) {
  console.log("[NUCHATAPI] getGroupAvailableChars");
  return service({
    url: `/session/basicInfo/${sessionId}`,
    method: 'get',
  });
}

/**
 * upload group avatar
 * @author Vivian
 * @Date 2024-03-19
 */
export async function uploadGroupAvatarApi(groupId, avatarFile) {
  console.log("[NUCHATAPI] upload group avatar");

  const formData = new FormData();
  formData.append("avatar", avatarFile);

  return service({
    url: `/session/uploadGroupAvatar/${groupId}`,
    method: 'post',
    data: formData,
    // headers: {
    //   'Content-Type': 'multipart/form-data',
    // },
  });
}

/**
 * upload file message
 * @author Vivian
 * @Date 2024-04-24
 */
export async function uploadFileMsg(sessId, file) {
  console.log("[NUCHATAPI] upload group avatar");

  const formData = new FormData();
  formData.append("msg", file);

  return service({
    url: `/message/uploadFile/${sessId}`,
    method: 'post',
    data: formData,
  });
}

/**
 * download group avatar
 * @author Vivian
 * @Date 2024-04-24
 */
export async function downloadFileMsg(msgId) {
  console.log("[NUCHATAPI] upload group avatar");

  return service({
    url: `/message/downloadFile/${msgId}`,
    method: 'get',
    responseType: 'blob'  // Important to handle binary data properly
  });
}

/**
 * Search user by email or phone number
 * @param {string} searchInfo user email or phone number
 * @author Vivian
 * @Date 2024-04-15
 */
export async function searchUser(searchInfo) {
  console.log("[NUCHATAPI] searchUser");
  return service({
    url: `/user/searchUser?searchInfo=${searchInfo}`,
    method: 'get',
  });
}

/**
 * Search user by email or phone number
 * @param {string} sessId session id
 * @param {string} keyword search keyword
 * @author Vivian
 * @Date 2024-05-14
 */
export async function searchHistory(sessId, keyword) {
  console.log("[NUCHATAPI] searchHistory");
  return service({
    url: `/session/searchMsg/${sessId}?keyword=${keyword}`,
    method: 'get',
  });
}

/**
 * Get user information or group information by scan qrCode
 * @param {string} qrCode QR code
 * @author Vivian
 * @Date 2024-04-15
 */
export async function getQRCodeInfo(qrCode) {
  console.log("[NUCHATAPI] getQRCodeInfo");
  return service({
    url: `/user/qrCode/${qrCode}`,
    method: 'get',
  });
}

/**
 * translate text
 * @author Vivian
 * @Date 2023-9-15
 */
export async function translate(params) {
  console.log("[NUCHATAPI] translate");
  return service({
    url: `/translate`,
    method: 'post',
    data: params,
  });
}

/**
 * Get all Configs
 * @author Vivian
 * @Date 2024-05-17
 */
export async function getConfigsApi() {
  console.log("[NUCHATAPI] getConfigs");
  const params = {
    withLanuages: "false",
  };
  return service({
    url: `/configs`,
    method: 'get',
    params
  });
}

/**
 * Get pop-up message
 * @param {string} lang language code
 * @author Vivian
 * @Date 2024-06-03
 */
export async function getPopupMsgApi(lang) {
  console.log("[NUCHATAPI] getPopupMsg");
  const params = {
    lang: lang ? lang : "en",
  };
  return service({
    url: `/popupMsg`,
    method: 'get',
    params
  });
}

/**
 * Pop-up message showed
 * @param {string} popupMsgId pop-up message id
 * @author Vivian
 * @Date 2024-06-04
 */
export async function msgPoppedUpApi(popupMsgId) {
  console.log("[NUCHATAPI] msgPoppedUp");
  return service({
    url: `/popupMsg/${popupMsgId}`,
    method: 'post',
  });
}