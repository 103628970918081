import "../../assets/styles/chat.css"

import React, { useEffect }from 'react';
import { useTranslation } from "react-i18next";
import { logEvent } from "firebase/analytics";
import { TextField } from "@mui/material";
import Button from "@mui/material/Button";
import { useForm, Controller } from 'react-hook-form';
import { ThemeProvider } from '@mui/material/styles';
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";

import { analytics } from "../../utils/firebaseAnalytics";
import { getSocket } from "../../socket";
import DialogWindowTemplate from "../../utils/DialogWindowTemplate";
import customTheme from "../../utils/customTheme";

/**
 * update group dialog
 * @param {function} props.onClose handle close the dialog
 * @param {Boolean} props.open the dialog is open?
 * @param {string} props.groupId the group id
 * @param {string} props.infoType the group info type, 'name' or 'desc' (default is 'desc')
 * @param {string} props.groupInfo the old group name or group description
 * @author Vivian
 * @date 2023-11-29
 */
export default function UpdateGroupDialog(props) {
  const socket = getSocket();
  const { t } = useTranslation();  //for showing different language text

  const { handleSubmit, control, setError, clearErrors, setValue, formState: { errors } } = useForm();

  useEffect(() => {
    clearErrors('groupInfo');
    setValue('groupInfo', props.groupInfo);
  }, [props.open]);

  const onSubmit = async (data) => {
    if (!data.groupInfo && props.infoType === "name") {
      setError('groupInfo', { type: 'manual', message: t('group.name.required') });
      return;
    }
    let params = { groupId: props.groupId};
    if(props.infoType === "name"){ params.groupName = data.groupInfo; }
    else{ params.groupDesc = data.groupInfo; }
    // logEvent(analytics, "update_group", { groupId: props.groupId, groupInfo: data.groupInfo });
    socket.emit("update_group", params);
  };

  const onClose = () => {
    clearErrors('groupInfo');
    setValue('groupInfo', '');
    props.onClose();
  }

  return (
    <DialogWindowTemplate
      title={t(props.infoType === 'name' ? "group.update.name" : "group.update.desc")}
      onClose={onClose}
      open={props.open}
    >
      <div>
        <form onSubmit={handleSubmit(onSubmit)}>

          <DialogContent>
            <div>
              <Controller
                name="groupInfo"
                control={control}
                rules={props.infoType === 'name' ? { required: t('group.name.required') } : {}}
                render={({ field }) => (

                  <ThemeProvider theme={customTheme}>
                    <TextField
                      {...field}
                      label={t(props.infoType === 'name' ? 'group.name' : 'group.desc')}
                      variant="outlined"
                      error={Boolean(errors.groupInfo)}
                      helperText={errors.groupInfo?.message}
                      style={{ width: '300px' }}
                      autoFocus
                      multiline={props.infoType !== 'name'} //multiline for description
                      rows={props.infoType !== 'name' ? 3 : 1}
                      inputProps={{
                        maxLength: props.infoType === 'name' ? 30 : 100 // 限制最多100个字符
                      }}
                    />
                  </ThemeProvider>

                )}
              />
            </div>
          </DialogContent>
          <DialogActions>
            <Button style={{ fontWeight: "bolder", color: "#513695" }} onClick={onClose}>
              {t('button.cancel')}
            </Button>
            <Button type="submit" style={{ fontWeight: "bolder", backgroundColor: "#f0dbf2", color: "#513695" }} >
              {t('group.update.submit')}
            </Button>
          </DialogActions>
        </form>
      </div>
    </DialogWindowTemplate>
  );
}
