import "../assets/styles/chat.css";

import React, { useEffect, useState }from 'react';
import { useTranslation } from "react-i18next";
import i18next from 'i18next';
import Button from '@mui/material/Button';
import { logEvent } from "firebase/analytics";
// import QuestionAnswerRoundedIcon from '@mui/icons-material/QuestionAnswerRounded';

import { analytics } from '../utils/firebaseAnalytics';
import { getSocket } from '../socket';
import { useChatSessions, useChatSessionsDispatch } from '../contexts/ChatSessionsContext';
import { getInpersonSession, getUserId, getLang, setCurrSessionId, setLang, getAuth } from '../utils/currUser';

import FilterBar from "./FilterBar";
import ChatSessionItem from './ChatSessionItem';
import LanguageSelector from "./LanguageSelector";
import Profile from "./profile/Profile";
import AddContact from "./AddContact";

/**
 * chat side bar for showing the session list and so on
 * @author Vivian
 * @date 2023-10-31
 */
const ChatSidebar = React.forwardRef(({setCurrSession, setCurrMsgs, handleSessionClick, handleOpenAddFriendDialog, handleOpenCreateGroupDialog, handleOpenJoinGroupDialog, setErrorCode, isConnected }, ref) => {

  const chatSessions = useChatSessions(); //[{chatSession}], all chat sessions array
  const chatSessionsDispatch = useChatSessionsDispatch();  // for edit chatSessions
  const { t } = useTranslation();  //for showing different language text
  const [friendFilter, setFriendFilter] = useState(""); //search friend text
  const [currLang, setCurrLang] = useState(getLang()); //current language
  const userAuth = getAuth();  //user auth info
  const socket = getSocket();

  useEffect(() => {
      setCurrLang(getLang);
  }, [i18next.language]);

  // //handle search by email - add friend
  // const handleSearch = (searchEmail) => {
  //   //record event on firebase
  //   // logEvent(analytics, 'add_friend', { searchText: searchEmail });

  //   console.log(`Searching for: ${searchEmail}`);
  //   socket.emit('add_friend', searchEmail);
  // };

  //deal with click in-person conversation button
  const onClickInpersonConvo = () => {
    if(!userAuth.prontoPkg){
      setErrorCode("FEATURE_AUTH_ERROR");
    }else{
      
    //record event on firebase
    logEvent(analytics, 'NuCHAT_Pronto_Click', {userId: getUserId().toString()}); 

    //send 'change_curr_session' event to server
    socket.emit('change_curr_session', null);

    //update the chatSession context
    chatSessionsDispatch({
      type: 'changeCurrSession',
      sessionId: null,
    });

    const chatSession = getInpersonSession();
    const messages = chatSession.messages;
    //update all settings related with current session
    setCurrSessionId(chatSession._id);  //update the current session id
    setCurrSession(chatSession);        //update the current session info
    setCurrMsgs(messages);  //update the current messages 

    console.log(`Open in-person conversation page.`);
    }
  };

  //handle user select a language
  const handleChangeLang = (langCode) => {
    //record event on firebase
    // logEvent(analytics, 'change_language', { currLang: langCode });

    setLang(langCode);
    socket.emit('change_lang', langCode);
  }

  return (
    <div id="sidebar-container">
      {!isConnected ?
        <div id="status-bar" style={{ 
          top: 0, 
          left: 0, 
          width: '100%', 
          backgroundColor: '#E6E6E8', 
          color: 'black', 
          textAlign: 'center',
          padding: '10px'
        }}>
          {t('status_bar.offline')}
        </div> : null
      }
      <div id="sidebar-profile-box">

        {/* language selector */}
        <LanguageSelector color="white" currLangCode={currLang} handleChangeLang={handleChangeLang} />

        {/* logo */}
        <img className="profile-box-logo"
          src="/images/nuchat_logo.png"
          alt='Nuwelcom'
        />

        {/* Profile */}
        <Profile></Profile>

      </div>

      {/* Search Bar */}
      <div id="sidebar-searchbar">
        <FilterBar placeholderText={t("search_box.search")} setFilter={setFriendFilter}></FilterBar>
        {/*add button for add friend or create group*/}
        <AddContact handleAddFriend={handleOpenAddFriendDialog} handleCreateGroup={handleOpenCreateGroupDialog} handleJoinGroup={handleOpenJoinGroupDialog} setErrorCode={setErrorCode}></AddContact>
      </div>

      {/* Chat Sesions list */}
      {(chatSessions && chatSessions.length > 0) ? (
        <div id="sidebar-session-list" ref={ref}>
          {chatSessions.map((chatSession) => {
            return (
              <ChatSessionItem
                socket={socket}
                chatSession={chatSession}
                friendFilter={friendFilter}
                handleSessionClick={handleSessionClick}
                key={chatSession._id}
              />
            );
          })}

        </div>
      ) : (
        <div className="no-conversations">
          <p style={{ fontWeight: "bold" }}>{t("no_session")}</p>
        </div>
      )}

      {/* for face to face conversation */}
      <div id="sidebar-inperson-button">
        <Button
        style={{color:"white", backgroundColor:"#501662", borderRadius:"7px", textTransform: 'none'}}
          variant="contained"
          onClick={onClickInpersonConvo}
        >
          {t("button.inperson")}
        </Button>
      </div>
    </div>
  );
});

export default ChatSidebar;
