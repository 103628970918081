import "../assets/styles/chat.css";

import React from 'react';
import TextField from '@mui/material/TextField';
import { outlinedInputClasses } from "@mui/material/OutlinedInput";
// import { inputLabelClasses } from "@mui/material/InputLabel";
import { styled } from "@mui/material/styles";
import IconButton from '@mui/material/IconButton';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos'
import ArrowbackIosIcon from "@mui/icons-material/ArrowBackIos";

import useFormInput from '../utils/useFormInput';
import { getSettings } from '../utils/currUser';

const StyledTextField = styled(TextField)({
  [`& fieldset`]: {
    borderRadius: 45,
  },
  [`& .${outlinedInputClasses.root} .${outlinedInputClasses.notchedOutline}`]: {
    borderColor: "#A24D84",
    // border: "none"
  },
  [`&:hover .${outlinedInputClasses.root} .${outlinedInputClasses.notchedOutline}`]: {
    borderColor: "#A24D84",
    // border: "none"
  },
  [`& .${outlinedInputClasses.root}.${outlinedInputClasses.focused} .${outlinedInputClasses.notchedOutline}`]: {
    borderColor: "#A24D84",
    // border: "none"
  },
  // [`& .${outlinedInputClasses.input}`]: {
  //   color: "green"
  // },
  // [`&:hover .${outlinedInputClasses.input}`]: {
  //   color: "red"
  // },
  // [`& .${outlinedInputClasses.root}.${outlinedInputClasses.focused} .${outlinedInputClasses.input}`]: {
  //   color: "purple"
  // },
  // [`& .${inputLabelClasses.outlined}`]: {
  //   color: "green"
  // },
  // [`&:hover .${inputLabelClasses.outlined}`]: {
  //   color: "red"
  // },
  // [`& .${inputLabelClasses.outlined}.${inputLabelClasses.focused}`]: {
  //   color: "purple"
  // }
});

/**
 * input message bar for in-person conversation
 * @author Vivian
 * @date 2023-9-14
 */
function InpersonInputBar({ placeholderText, handleSend }) {
  const settings = getSettings();
  const inputMsg = useFormInput('', settings.msgLimitChars, null);  //the inputed message

  //handle left person submit the message
  async function handleLeftSubmit() {
    handleSend(inputMsg.value, "left");
    inputMsg.clearValue();
  };

  //handle submit the message
  async function handleRightSubmit() {
    handleSend(inputMsg.value, "right");
    inputMsg.clearValue();
  };

  return (
    <div style={{ borderColor: "purple" }}>
      <StyledTextField
        className="input-msg-bar"
        multiline
        value={inputMsg.value} 
        onChange={inputMsg.onChange} 
        onKeyDown={inputMsg.onKeyDown}
        // {...inputMsg}
        placeholder={placeholderText}
        InputProps={{
          startAdornment: (
            <IconButton
              color="primary"
              aria-label="send"
              component="span"
              style={{
                height: "4vh",
                width: "4vh",
                marginRight: "1vw",
                background: "#A24D84",
                color: "white",
                paddingLeft: "15px",
              }}
              onClick={handleLeftSubmit}
            >
              <ArrowbackIosIcon />
            </IconButton>
          ),
          endAdornment: (
            <IconButton
              color="primary"
              aria-label="send"
              component="span"
              style={{
                height: "4vh",
                width: "4vh",
                marginLeft: "1vw",
                background: "#F0b354",
                color: "white",
              }}
              onClick={handleRightSubmit}
            >
              <ArrowForwardIosIcon />
            </IconButton>
          ),
        }}
      />
    </div>
  );
}

export default InpersonInputBar;
