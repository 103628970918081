import "../assets/styles/chat.css";

import React, {useEffect} from 'react';
import TextField from '@mui/material/TextField';
import { outlinedInputClasses } from "@mui/material/OutlinedInput";
// import { inputLabelClasses } from "@mui/material/InputLabel";
import { styled } from "@mui/material/styles";
import IconButton from '@mui/material/IconButton';
import ClearIcon from '@mui/icons-material/Clear';
import SearchIcon from '@mui/icons-material/Search';
import { useTranslation } from "react-i18next";

import useFormInput from '../utils/useFormInput';


const StyledTextField = styled(TextField)({
  [`& fieldset`]: {
    borderRadius: 20,
  },
  [`& .${outlinedInputClasses.root} .${outlinedInputClasses.notchedOutline}`]: {
    // borderColor: "#A24D84",
    border: "none"
  },
  [`&:hover .${outlinedInputClasses.root} .${outlinedInputClasses.notchedOutline}`]: {
    // borderColor: "#A24D84",
    border: "none"
  },
  [`& .${outlinedInputClasses.root}.${outlinedInputClasses.focused} .${outlinedInputClasses.notchedOutline}`]: {
    // borderColor: "#A24D84",
    border: "none"
  },
  [`& .${outlinedInputClasses.input}`]: {
    // color: "green"
    padding: "2px 3px 2px 2px" 
  },
  // [`&:hover .${outlinedInputClasses.input}`]: {
  //   color: "red"
  // },
  // [`& .${outlinedInputClasses.root}.${outlinedInputClasses.focused} .${outlinedInputClasses.input}`]: {
  //   color: "purple"
  // },
  // [`& .${inputLabelClasses.outlined}`]: {
  //   color: "green"
  // },
  // [`&:hover .${inputLabelClasses.outlined}`]: {
  //   color: "red"
  // },
  // [`& .${inputLabelClasses.outlined}.${inputLabelClasses.focused}`]: {
  //   color: "purple"
  // }
});
/**
 * search bar in sider bar
 * @author Vivian
 * @date 2023-9-4
 */
function FilterBar({ placeholderText, setFilter }) {
  const searchText = useFormInput('', 40, null);  //input text
  const {t} = useTranslation();  //for showing different language text

  useEffect(() => {
    setFilter(searchText.value);
  }, [searchText]);

  const handleClear = () => {
    searchText.clearValue();
  };

  return (
      <StyledTextField
        className="searchbar"
        value={searchText.value} 
        onChange={searchText.onChange} 
        onKeyDown={searchText.onKeyDown}
        // {...searchText}
        placeholder={placeholderText}
        
        InputProps={{
          startAdornment: (
            <IconButton style={{ paddingLeft: 0, paddingRight: 0}}>
              <SearchIcon style={{ fill: "#513695"}} />
            </IconButton>
          ),
          style:{paddingLeft: 7, paddingRight: 7},
          endAdornment: (
            <IconButton style={{ paddingLeft: 0, paddingRight: 0}} onClick={handleClear}>
              <ClearIcon style={{ fontSize: 16, fill: "#513695"}}/>
            </IconButton>
          ),
        }}
      />
  );
}

export default FilterBar;
