import "../assets/styles/chat.css";

import React from 'react';
import TextField from '@mui/material/TextField';
import { outlinedInputClasses } from "@mui/material/OutlinedInput";
// import { inputLabelClasses } from "@mui/material/InputLabel";
import { styled } from "@mui/material/styles";
import IconButton from '@mui/material/IconButton';
import SearchIcon from '@mui/icons-material/Search';
import ClearIcon from '@mui/icons-material/Clear';

import useFormInput from '../utils/useFormInput';


const StyledTextField = styled(TextField)({
  [`& fieldset`]: {
    borderRadius: 20,
  },
  [`& .${outlinedInputClasses.root} .${outlinedInputClasses.notchedOutline}`]: {
    // borderColor: "#A24D84",
    border: "none"
  },
  [`&:hover .${outlinedInputClasses.root} .${outlinedInputClasses.notchedOutline}`]: {
    // borderColor: "#A24D84",
    border: "none"
  },
  [`& .${outlinedInputClasses.root}.${outlinedInputClasses.focused} .${outlinedInputClasses.notchedOutline}`]: {
    // borderColor: "#A24D84",
    border: "none"
  },
  [`& .${outlinedInputClasses.input}`]: {
    // color: "green"
    padding: "2px 3px 2px 2px" 
  },
});
/**
 * search user bar
 * @author Vivian
 * @date 2023-11-15
 */
function SearchUserBar({ placeholderText, handleSearch }) {
  const searchText = useFormInput('', 40, onSearch);  //input text

  //handle submit
  async function onSearch (){
    handleSearch(searchText.value);
  };

  const handleClear = () => {
    searchText.clearValue();
  };

  return (
      <StyledTextField
        className="searchbar"
        value={searchText.value} 
        onChange={searchText.onChange} 
        onKeyDown={searchText.onKeyDown}
        // {...searchText}
        placeholder={placeholderText}
        InputProps={{
          startAdornment: (
            <IconButton style={{ paddingLeft: 0, paddingRight: 0}} onClick={onSearch}>
              <SearchIcon style={{ fill: "#513695"}} />
            </IconButton>
          ),
          style:{paddingLeft: 7, paddingRight: 7},
          endAdornment: (
            <IconButton style={{ paddingLeft: 0, paddingRight: 0}} onClick={handleClear}>
              <ClearIcon style={{ fontSize: 16, fill: "#513695"}}/>
            </IconButton>
            
          ),
        }}
      />
  );
}

export default SearchUserBar;