import "../../assets/styles/chat.css";

import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Avatar, Menu, MenuItem } from "@mui/material";
import { logEvent } from "firebase/analytics";
import LogoutIcon from '@mui/icons-material/Logout';
import QrCodeIcon from '@mui/icons-material/QrCode';
import PersonIcon from '@mui/icons-material/Person';

import { analytics } from "../../utils/firebaseAnalytics";
import {
  useChatSessions,
  useChatSessionsDispatch,
} from "../../contexts/ChatSessionsContext";
import { getUser, getUserId } from "../../utils/currUser";
import userService from "../../services/userService";
import QRCodeDialog from "../QRCodeDialog";
import MyProfile from "./MyProfile";
// import { padding } from "@mui/system";

/**
 * user profile
 * @author Vivian
 * @date 2023-9-4
 */
const Profile = () => {
  let user = getUser(); //user information
  const chatSessionsDispatch = useChatSessionsDispatch(); // for edit chatSessions
  const [profileAnchor, setProfileAnchor] = useState(null);
  const [showQRCode, setShowQRCode] = useState(false); //This state is for QR code dialog box
  const [showMyProfile, setShowMyProfile] = useState(false); //This state is for My Profile dialog box
  const { t } = useTranslation(); //for showing different language text

  //close the "My QR Code" dialog
  const handleQRCodeClose = () => {
    setShowQRCode(false);
  };

  //close the "My Profile" dialog
  const handleMyProfileClose = () => {
    setShowMyProfile(false);
  };

  //open the profile operation list
  function handleProfileClick(event) {
    setProfileAnchor(event.currentTarget);
  }

  //close the profile operation list
  function handleProfileClose() {
    setProfileAnchor(null);
  }

  //handle click "My Profile"
  async function handleMyProfile() {
    //record event on firebase
    logEvent(analytics, "NuCHAT_MyProfile_Click", { userId: getUserId().toString()});

    setShowMyProfile(true);  //open the MyProfile dialog box
    handleProfileClose();  //close the menu list
  }

  //handle click my QR code
  async function handleMyQRCode() {
    //record event on firebase
    logEvent(analytics, "NUWELCOM_QRCode_User_show", { userId: getUserId().toString()});

    setShowQRCode(true);  //open the QRcode dialog box
    handleProfileClose();  //close the menu list
  }

  //handle user logout
  async function handleLogout() {
    //record event on firebase
    // logEvent(analytics, "user_logout", { userId: getUserId() });

    //clear the chatSession context
    chatSessionsDispatch({
      type: "logout",
    });

    userService.logout();
  }

  return (
    <div
      style={{
        position: "absolute",
        right: "10px",
      }}
    >
      {/* avatar */}
      <Avatar
        style={{
          backgroundColor: "#8F3E97",
          color: "rgb(255, 255, 255, 0.8)",
        }}
        className="avatar clickable"
        id="profile-avatar"
        src={user.avatar ? user.avatar : "n/a"}
        alt={user.fullName}
        onClick={handleProfileClick}
      />

      {/* My Profile dialog*/}
      <MyProfile
        onClose={handleMyProfileClose}
        open={showMyProfile}
      />

      {/* My QR code dialog*/}
      <QRCodeDialog
        QRCodeStr={user.qrCode}
        onClose={handleQRCodeClose}
        open={showQRCode}
        title={user.fullName}
      />

      {/* operation list */}
      <Menu
        id="profile-menu"
        anchorEl={profileAnchor}
        keepMounted
        open={Boolean(profileAnchor)}
        onClose={handleProfileClose}
      >
        {/* TODO: open this menu to use "My Profile" feature */}
        <MenuItem key="my-profile" onClick={handleMyProfile}>
          <PersonIcon className="menu-icon" />{t("profile_dropdown.myprofile")}
        </MenuItem>

        <MenuItem key="my-qrcode" onClick={handleMyQRCode}>
          <QrCodeIcon className="menu-icon" />{t("profile_dropdown.myqrcode")}
        </MenuItem>
        <MenuItem key="logout" onClick={handleLogout}>
          <LogoutIcon className="menu-icon" />{t("profile_dropdown.logout")}
        </MenuItem>
      </Menu>
    </div>
  );
};

export default Profile;
