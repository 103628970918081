import React from 'react';
import configUrl from "../configs/config.json";
import { downloadFileMsg } from '../services/nuchatApi';

// change the file size to a human-readable format
function formatFileSize(bytes) {
  if (bytes >= 1048576) { // 1024 * 1024
    return (bytes / 1048576).toFixed(2) + ' MB';
  } else if (bytes >= 1024) {
    return (bytes / 1024).toFixed(2) + ' KB';
  } else {
    return bytes + ' bytes';
  }
}

/*
 * FileMessage - for showing the file message content
  * @param {string} msgId - the message id
  * @param {object} fileInfo - the file information, like:
  * {
        "url": "private/upload/msg/msg-65f8455acd8b29c00db7bfd2-1713980430246.docx",
        "fileName": "3000高频词汇.docx",
        "fileSize": 115318,
        "fileType": "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
    }
  * @author Vivian
  * @date 2024-04-25
 */
function FileMessage({ msgId, fileInfo }) {
  const formattedSize = formatFileSize(fileInfo.fileSize);

  // download the file
  async function downloadFile() {
    try{
      const res = await downloadFileMsg(msgId);

      // create a blob url for the file download
      const url = window.URL.createObjectURL(res);
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', fileInfo.fileName);
      document.body.appendChild(link);
      link.click();

      // clean up
      link.parentNode.removeChild(link);
      window.URL.revokeObjectURL(url);
    } catch(error) {
      console.error('Error downloading file:', error);
    }
}

  return (
    <div onClick={downloadFile}>
      {fileInfo && fileInfo.thumbnailUrl ? (
        //show thumbnail
        <div className="file-message">
          <img src={`${configUrl.api.nuchatUrl}/${fileInfo.thumbnailUrl}`} alt="Thumbnail" />
        </div>
      ) : (
        //show file details
        <div className="file-message">
          <div className="file-details">
            <div style={{ color: "black" }}>{fileInfo.fileName}</div>
            <div style={{ color: "#c0bbbb", fontSize: "12px" }}>{formatFileSize(fileInfo.fileSize)}</div>
          </div>
          <div className="file-icon">
            <svg viewBox="0 0 24 24" width="48" height="48" fill="currentColor">
              <path d="M14 2H6c-1.1 0-2 .9-2 2v16c0 1.1.9 2 2 2h12c1.1 0 2-.9 2-2V8l-6-6zm2 16H8v-2h8v2zm0-4H8v-2h8v2zm-3-5V3.5L18.5 9H13z" />
            </svg>
          </div>
        </div>
      )}

    </div>
  );
}

export default FileMessage;