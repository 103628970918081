import "../assets/styles/chat.css";

import React, { useState, useEffect, useRef } from 'react';
import ReactLoading from 'react-loading';
import { toast } from 'react-hot-toast';
import { useTranslation } from "react-i18next";
import { logEvent } from "firebase/analytics";

import { analytics } from '../utils/firebaseAnalytics';
import { initSocket, getSocket } from '../socket';
import ChatService from '../services/chatService';
import UserService from '../services/userService';
import { getCurrSessionId, getLang, getToken, getUserId, removeAuthSession, setCurrSessionId, setLang } from '../utils/currUser'
import { useChatSessions, useChatSessionsDispatch } from '../contexts/ChatSessionsContext';

import ChatSidebar from '../components/ChatSidebar';
import MessagePanel from '../components/MessagePanel';
import AddFriendDialog from "../components/AddFriendDialog";
import CreateGroupDialog from "../components/group/CreateGroupDialog";
import AddMemberDialog from "../components/group/AddMemberDialog";
import ErrorDialog from "../utils/ErrorDialog";
import UpdateGroupDialog from "../components/group/UpdateGroupDialog";
import JoinGroupDialog from "../components/group/JoinGroupDialog";
import PopupMsgDialog from "../utils/PopupMsgDialog";
import { getPopupMsgApi } from "../services/nuchatApi";

/**
 * main page for chat, deal with all socket connection and listening on events
 * @author Vivian
 * @date 2023-9-1
 */
function Chat() {

  const [isConnected, setIsConnected] = useState(false); // is the socket connected
  const chatSessions = useChatSessions(); //[{chatSession}], all chat sessions array
  const chatSessionsDispatch = useChatSessionsDispatch(); // for edit chatSessions
  const [currSession, setCurrSession] = useState({}); // current session information
  const [currMsgs, setCurrMsgs] = useState([]); // all messages in current session
  const [isLoading, setIsLoading] = useState(false);

  const [addFriendDialogOpen, setAddFriendDialogOpen] = useState(false); //set the add friend dialog is open
  const [createGroupDialogOpen, setCreateGroupDialogOpen] = useState(false); //set the create group dialog is open
  const [joinGroupDialogOpen, setJoinGroupDialogOpen] = useState(false); //set the create group dialog is open
  const [updateGroupNameDialogOpen, setUpdateGroupNameDialogOpen] = useState(false); //set the update group name dialog is open
  const [updateGroupDescDialogOpen, setUpdateGroupDescDialogOpen] = useState(false); //set the update group description dialog is open
  const [addMemberDialogOpen, setAddMemberDialogOpen] = useState(false); //set the add group member dialog is open
  const [errorCode, setErrorCode] = useState(null); //set the error code for error dialog
  const [popupMsg, setPopupMsg] = useState({}); //set the popup message for popup message dialog
  // const [popupMsgDialogOpen, setPopupMsgDialogOpen] = useState(false);
  const sessionListRef = useRef(null);  //for auto scroll session list to top

  let socket = null;

  let currMsgsRef = useRef(); //for get the latest value of currMsgs
  currMsgsRef.current = currMsgs;

  const { t } = useTranslation(); //for showing different language text

  //deal with socket 'connect' event
  const onConnect = async () => {
    //record event on firebase
    // logEvent(analytics, 'socket_connect', { userId: getUserId() });

    setIsConnected(true);
    console.log(`[SOCKET] Socket connected.`);
  }

  //deal with socket 'disconnect' event
  const onDisconnect = () => {
    //record event on firebase
    // logEvent(analytics, 'socket_disconnect', { userId: getUserId() });
    setIsConnected(false);
    console.log(`[SOCKET] Socket disconnected.`);
  }

  //deal with socket 'auth_error' event
  const onConnectError = (err) => {
    //record event on firebase
    // logEvent(analytics, 'socket_connect_error', { userId: getUserId(), error: err.error });

    console.log("[SOCKET] Event: auth_error");
    if (err.message) {
      console.log(`[SOCKET] Error Code: ${err.error}, Message: ${err.message}`);
    }
    // this.setIsConnected(false);
    removeAuthSession();
    UserService.authError();
  }

  //deal with socket 'friend_online' event
  const onFriendOnline = (params) => {
    console.log("[SOCKET] Event: friend_online");
    if (params.success) {
      chatSessionsDispatch({
        type: 'friendOnline',
        userIds: params.data.userIds,
        status: params.data.onlineStatus,
      });
    } else {
      // toast.error(t(params.error));
      setErrorCode(params.error);
    }
  }

  //deal with socket 'lang_changed' event
  const onLangChanged = (params) => {
    console.log(`[SOCKET] Event: lang_changed, lang: ${params.data}`);
    logEvent(analytics, 'NuCHAT_ChangeLang_Submit', { userId: getUserId().toString(), lang: params.data }); //record event on firebase

    if (params.success) {
      if (params.data !== getLang() && params.operator.socketId !== socket.id) { //if current socket is not the one who change the lang
        setLang(params.data);
        toast.success(t("lang_changed"));
      }
    }
  }

  //deal with socket 'session_created' event (Vivian modified on 2024-02-02)
  const onSessionAdded = async (params) => {
    console.log("[SOCKET] Event: session_created");
    if (params.success) {
      setAddFriendDialogOpen(false);
      setCreateGroupDialogOpen(false);
      setJoinGroupDialogOpen(false);
      let chatSession = await ChatService.initSession(params.data.session);

      //record event on firebase
      // logEvent(analytics, 'socket_session_created', { sessionid: params.data.session._id });

      if (params.data.session.type !== "group" && params.data.friendStatus.onlineStatus == 'online') {
        chatSession.isOnline = true;
      }
      chatSessionsDispatch({
        type: 'sessionAdded',
        data: chatSession,
      });

      if (params.operator.socketId == socket.id) { //if current socket is the one who add the friend
        handleChangeSession(socket, chatSession); //change current session
        scrollSessionToTop();
        const userId = getUserId();
        if (params.data.session.type === "group" && params.data.session.admins.includes(userId)) {
          toast.success(t("group.add_group.success"));
        } else if (params.data.session.type === "group") {
          toast.success(t("group.join_group.success"));
        } else {
          toast.success(t("add_friend.success"));
        }
      }

    } else {
      // toast.error(t(params.error));
      setErrorCode(params.error);
    }
  }

  //deal with socket 'session_deleted' event
  const onSessionDeleted = async (params) => {
    console.log("[SOCKET] Event: session_deleted");

    if (params.success) {
      //record event on firebase
      // logEvent(analytics, 'session_deleted', { sessionid: params.data._id });

      //update the chatSession context
      chatSessionsDispatch({
        type: "sessionDeleted",
        session: params.data,
        isRemove: params.operator.socketId == socket.id, //for operator, delete all the history
      });

      //for operator, the deleted session is the current session
      if ((params.operator.socketId == socket.id)) {
        setCurrSession({}); //when currSession is empty, the system will automatically change to the first session
        scrollSessionToTop();
        toast.success(t('delete_session.success'));
      }

    } else {
      // toast.error(t(params.error));
      setErrorCode(params.error);
    }
  };

  //deal with socket 'received_message' event
  const onReceivedMessage = async (params) => {
    console.log("[SOCKET] Event: received_message");
    if (params.success) {
      //record event on firebase
      // logEvent(analytics, 'socket_receive_message', { messageId: params.data._id });

      chatSessionsDispatch({
        type: 'msgReceived',
        data: params.data,
        // setCurrMsgs: setCurrMsgs,
      });

      //send 'read_messages' event to server
      if (params.data.sessionId === getCurrSessionId()) {
        socket.emit('read_messages', params.data.sessionId);
      }
    } else {
      // toast.error(t(params.error));
      setErrorCode(params.error);
    }
  }

  //deal with socket 'updated_message' event
  const onUpdateMessage = async (params) => {
    console.log("[SOCKET] Event: updated_message");
    if (params.success) {
      //record event on firebase
      // logEvent(analytics, 'socket_update_message', { messageId: params.data._id });

      //update the context
      chatSessionsDispatch({
        type: 'msgUpdated',
        data: params.data,
        // setCurrMsgs: setCurrMsgs,
      });
    } else {
      // toast.error(t(params.error));
      setErrorCode(params.error);
    }
  }

  //deal with socket 'messages_read' event
  const onMessageRead = async (params) => {
    console.log("[SOCKET] Event: messages_read");
    if (params.success) {
      //record event on firebase
      // logEvent(analytics, 'socket_messages_read', { sessionId: params.data._id });

      chatSessionsDispatch({
        type: 'msgRead',
        data: params.data,
      });

    } else {
      setErrorCode(params.error);
    }
  }

  //deal with socket 'group_member_added' event
  const onGroupMemberAdded = async (params) => {
    console.log("[SOCKET] Event: group_member_added");
    if (params.success) {
      setAddMemberDialogOpen(false);

      //record event on firebase
      // logEvent(analytics, 'group_member_added', { sessionId: params.data._id, memberId: params.data.membersDetail[0].memberId });

      await chatSessionsDispatch({
        type: 'groupMemberAdded',
        session: params.data,
        // setCurrSession: setCurrSession,
      });

      if (params.operator.socketId == socket.id) { //if current socket is the one who add the member
        toast.success(t("group.add_member.success"));
      }

    } else {
      setErrorCode(params.error);
    }
  }

  //deal with socket 'group_updated' event
  const onGroupUpdated = async (params) => {
    console.log("[SOCKET] Event: group_updated");
    if (params.success) {
      setUpdateGroupNameDialogOpen(false);
      setUpdateGroupDescDialogOpen(false);

      //record event on firebase
      // logEvent(analytics, 'group_updated', { sessionId: params.data._id, groupName: params.data.groupName });

      await chatSessionsDispatch({
        type: 'groupUpdated',
        session: params.data,
      });

      if (params.operator.socketId == socket.id) { //if current socket is the one who remove the member
        toast.success(t("group.update.success"));
      }

    } else {
      setErrorCode(params.error);
    }
  }

  //deal with socket 'group_member_removed' event
  const onGroupMemberRemoved = async (params) => {
    console.log("[SOCKET] Event: group_member_removed");
    if (params.success) {
      //record event on firebase
      // logEvent(analytics, 'group_member_removed', { sessionId: params.data._id, memberId: params.data.membersDetail[0].memberId });

      await chatSessionsDispatch({
        type: 'groupMemberRemoved',
        session: params.data,
        // setCurrSession: setCurrSession,
      });

      if (params.operator.socketId == socket.id) { //if current socket is the one who remove the member
        toast.success(t("group.remove_member.success"));
      }

    } else {
      setErrorCode(params.error);
    }
  }

  //connect socket
  const socketConnect = async () => {

    console.log("[SOCKET] Socket connecting...");
    //init socket
    await initSocket();
    socket = getSocket();
    console.log(`${socket}`);

    try {
      //connect socket
      if (socket && socket.connected == false) {
        //listening on events
        socket.on('connect', onConnect);
        socket.on('disconnect', onDisconnect);
        socket.on('auth_error', onConnectError);
        socket.on('friend_online', onFriendOnline);
        socket.on('session_created', onSessionAdded);
        socket.on('session_deleted', onSessionDeleted);
        socket.on('lang_changed', onLangChanged);
        // socket.on('session_deleted', (params) => onFriendDeleted(params, chatSessions));
        socket.on('received_message', onReceivedMessage);
        socket.on('updated_message', onUpdateMessage);
        socket.on('messages_read', onMessageRead);
        socket.on('group_member_added', onGroupMemberAdded);
        socket.on('group_member_removed', onGroupMemberRemoved);
        socket.on('group_updated', onGroupUpdated);
        console.log(`[SOCKET] Socket listening on every event.`);

        await socket.connect();
      }
      return true;
    } catch (error) {
      console.log(`[SOCKET] Socket connecting error: ${error}`);
      return false;
    }
  }

  //disconnect socket
  const socketDisconnect = async () => {
    if (socket) {
      //stop listening on events
      socket.off('connect');
      socket.off('disconnect');
      socket.off('auth_error');
      socket.off('friend_online');
      socket.off('session_created');
      socket.off('session_deleted');
      socket.off('lang_changed');
      socket.off('received_message');
      socket.off('updated_message');
      socket.off('messages_read');
      socket.off('group_member_added');
      socket.off('group_member_removed');
      socket.off('group_updated');

      console.log(`[SOCKET] Socket stop listening on every event.`);

      //disconnect socket
      socket.disconnect();
    }
  }

  // //get all user's messages and connect socket
  // const init = async () => {
  //   await socketConnect();//connnect the socket
  //   initContent();//init content
  // }

  const initContent = async () => {
    setIsLoading(true);
    //get init information from server
    const result = await ChatService.initContent();

    //put the init information into context to share with all the other components
    await chatSessionsDispatch({
      type: 'init',
      data: result,
    });

    const socket = getSocket();
    socket.emit("online_friends");

    setIsLoading(false);
  }

  const getPopupMsg = async () => {
    const result = await getPopupMsgApi(getLang());
    setPopupMsg(result.data ? result.data : {});
  }

  useEffect(() => {
    // init(); //get all user's messages and connect socket
    if (!socket || !socket.connected) {
      socketConnect(); //connect socket
    }

    logEvent(analytics, 'NuCHAT_Main_Show', { userId: getUserId().toString() }); //record event on firebase

    return () => {
      //disconnet the socket
      socketDisconnect();
    };
  }, []);

  useEffect(() => {
    if (isConnected) {
      initContent();
      getPopupMsg();
    }
  }, [isConnected]);

  {/* A Timer uses to monitor the socket connection */ }
  const timer_ms = 30 * 1000;
  useEffect(() => {
    const interval = setInterval(() => {
      if (!socket || !socket.connected) {
        socketConnect();
      }
    }, timer_ms);

    return () => clearInterval(interval); // This represents the unmount function, in which you need to clear your interval to prevent memory leaks.
  }, [])
  {/*Timer End*/ }

  useEffect(() => {
    // for setting the init current session and current messages
    // will default choose the first session ( which have the latest message )
    // also will work for add session and delete session
    const socket = getSocket();
    if (!currSession._id && chatSessions && chatSessions.length > 0) {
      if (socket) {
        handleChangeSession(socket, chatSessions[0]);
      }
      else {
        setCurrSession(chatSessions[0]);
        setCurrMsgs(chatSessions[0].messages);
      }
    }
    else if (currSession._id && currSession._id != 'in-person-conversation') { //this is for add member or remove member in group
      const session = chatSessions.find((session) => session._id === currSession._id);
      // if current session is still in the chatSessions
      if (session) {
        setCurrSession(session);
        setCurrMsgs(session.messages);
      }
      // if current session is not in the chatSessions, then set the first session as current session
      else {
        if (socket) {
          handleChangeSession(socket, chatSessions[0]);
        }
        else {
          setCurrSession(chatSessions[0]);
          setCurrMsgs(chatSessions[0].messages);
        }
      }
    }

  }, [chatSessions]);

  //updated the current message list
  const handleUpdateCurrMsgs = (newMsg) => {
    // console.log("HandleUpdateCurrMsgs - ref value:", currMsgsRef.current);
    // console.log("HandleUpdateCurrMsgs:", currMsgs);
    setCurrMsgs(currMsgsRef.current.map(message => {
      if (message._id === newMsg._id) {
        return { ...message, message: newMsg.message };
      } else {
        return message;
      }
    }));
  }

  //scroll the session list to the top
  const scrollSessionToTop = () => {
    if (sessionListRef.current) {
      sessionListRef.current.scrollTop = 0;
    }
  };

  // useEffect(() => {
  //   console.log("Updated currMsgs:", currMsgs);
  // }, [currMsgs]);

  //open the add friend dialog
  const handleOpenAddFriendDialog = () => {
    //record event on firebase
    // logEvent(analytics, 'add_friend_dialog_open');

    setAddFriendDialogOpen(true);
  }

  //close the add friend dialog
  const handleCloseAddFriendDialog = () => {
    setAddFriendDialogOpen(false);
  }

  //open the create group dialog
  const handleOpenCreateGroupDialog = () => {
    //record event on firebase
    // logEvent(analytics, 'create_group_dialog_open');

    setCreateGroupDialogOpen(true);
  }

  //close the create group dialog
  const handleCloseCreateGroupDialog = () => {
    setCreateGroupDialogOpen(false);
  }

  //open the create group dialog
  const handleOpenJoinGroupDialog = () => {
    //record event on firebase
    logEvent(analytics, 'NuCHAT_Group_Join_Click', { userId: getUserId().toString() });

    setJoinGroupDialogOpen(true);
  }

  //close the create group dialog
  const handleCloseJoinGroupDialog = () => {
    setJoinGroupDialogOpen(false);
  }

  //open the update group name dialog
  const handleOpenUpdateGroupNameDialog = () => {
    //record event on firebase
    logEvent(analytics, 'NuCHAT_Group_NameEdit_Click', { userId: getUserId().toString(), sessId: currSession._id });

    setUpdateGroupNameDialogOpen(true);
  }

  //close the update group name dialog
  const handleCloseUpdateGroupNameDialog = () => {
    setUpdateGroupNameDialogOpen(false);
  }

  //open the update group dialog
  const handleOpenUpdateGroupDescDialog = () => {
    //record event on firebase
    logEvent(analytics, 'NuCHAT_Group_DescEdit_Click', { userId: getUserId().toString(), sessId: currSession._id });

    setUpdateGroupDescDialogOpen(true);
  }

  //close the update group description dialog
  const handleCloseUpdateGroupDescDialog = () => {
    setUpdateGroupDescDialogOpen(false);
  }

  //open the add group member dialog
  const handleOpenAddMemberDialog = () => {
    //record event on firebase
    logEvent(analytics, 'NuCHAT_Group_AddMem_Click', { userId: getUserId().toString(), sessId: currSession._id });

    setAddMemberDialogOpen(true);
  }

  //close the add friend dialog
  const handleCloseAddMemberDialog = () => {
    setAddMemberDialogOpen(false);
  }

  //handle session change - change the current session
  const handleChangeSession = (socket, chatSession) => {
    let sessionId = null;
    let session = {};
    let messages = [];
    if (chatSession) {
      sessionId = chatSession._id;
      session = chatSession;
      messages = chatSession.messages
    }

    //record event on firebase
    logEvent(analytics, 'NuCHAT_Sess_Click', { userId: getUserId().toString(), sessId: sessionId });

    //send 'read_messages' event to server
    socket.emit('read_messages', sessionId);

    //update the chatSession context
    chatSessionsDispatch({
      type: 'changeCurrSession',
      sessionId: sessionId,
    });

    //update all settings related with current session
    setCurrSessionId(sessionId);  //update the current session id
    setCurrSession(session);        //update the current session info
    setCurrMsgs(messages);  //update the current messages 
  }

  return (
    <div id="main-container">
      {isLoading ?
        <div style={{ position: "fixed", top: 0, left: 0, width: "100%", height: "100%", display: "flex", justifyContent: "center", alignItems: "center", zIndex: "999" }}>
          <ReactLoading type="spinningBubbles" color="purple" width={100} height={100} />
        </div> : null
      }

      {/* left: ChatSidebar */}
      <ChatSidebar
        ref={sessionListRef}
        chatSessions={chatSessions}
        setCurrSession={setCurrSession}
        setCurrMsgs={setCurrMsgs}
        handleSessionClick={handleChangeSession}
        handleOpenAddFriendDialog={handleOpenAddFriendDialog}
        handleOpenCreateGroupDialog={handleOpenCreateGroupDialog}
        handleOpenJoinGroupDialog={handleOpenJoinGroupDialog}
        setErrorCode={setErrorCode}
        isConnected={isConnected}
      />

      {/* right: MessagePanel */}
      <MessagePanel
        chatSession={currSession}
        messages={currMsgs}
        setMessages={setCurrMsgs}
        updateMessages={handleUpdateCurrMsgs}
        setCurrSession={setCurrSession}
        handleOpenAddMemberDialog={handleOpenAddMemberDialog}
        handleOpenUpdateGroupNameDialog={handleOpenUpdateGroupNameDialog}
        handleOpenUpdateGroupDescDialog={handleOpenUpdateGroupDescDialog}
        setErrorCode={setErrorCode} />

      {/* add friend dialog */}
      <AddFriendDialog open={addFriendDialogOpen} onClose={handleCloseAddFriendDialog}></AddFriendDialog>

      {/* create group dialog */}
      <CreateGroupDialog open={createGroupDialogOpen} onClose={handleCloseCreateGroupDialog}></CreateGroupDialog>

      {/* join group dialog */}
      <JoinGroupDialog open={joinGroupDialogOpen} onClose={handleCloseJoinGroupDialog}></JoinGroupDialog>

      {/* update group name dialog */}
      <UpdateGroupDialog open={updateGroupNameDialogOpen} onClose={handleCloseUpdateGroupNameDialog} groupId={currSession._id} infoType='name' groupInfo={currSession.groupName}></UpdateGroupDialog>

      {/* update group desc dialog */}
      <UpdateGroupDialog open={updateGroupDescDialogOpen} onClose={handleCloseUpdateGroupDescDialog} groupId={currSession._id} infoType='desc' groupInfo={currSession.groupDesc}></UpdateGroupDialog>

      {/* add group member dialog */}
      <AddMemberDialog open={addMemberDialogOpen} onClose={handleCloseAddMemberDialog} groupId={currSession._id}></AddMemberDialog>

      {/* pop-up message dialog */}
      <PopupMsgDialog popupMsg={popupMsg} setPopupMsg={setPopupMsg} />

      {/* error dialog */}
      <ErrorDialog errorCode={errorCode} setErrorCode={setErrorCode} />
    </div>
  );
}

export default Chat;
