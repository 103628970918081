import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';
// import { getToken, getAuth } from './currUser';
import NoAuth from '../pages/NoAuth';
import Loading from '../pages/Loading';

// handle the private routes
const PrivateRoutes = ({isLoggedIn, isAuthed, isLoading}) => {
  console.log(`[PrivateRoutes]: isLoggedIn:${isLoggedIn}, isAuthed: ${isAuthed}, , isLoading: ${isLoading}`);
  return isLoggedIn ? (isLoading ? <Loading />: (isAuthed ? <Outlet />: <NoAuth />)) : <Navigate to="/login" />
}

export default PrivateRoutes;