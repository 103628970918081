import '../assets/styles/chat.css'
import Configration from "../configs/config.json"

import { Avatar, Badge } from "@mui/material";
import i18next from 'i18next';
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

// a avatar for chat sessions sidebar
const normalAvatar = (chatSession) => {
  return <Avatar
    className="avatar"
    alt={chatSession.groupName}
    src={chatSession.groupAvatar ? chatSession.groupAvatar : "n/a"}
    style={{ backgroundColor: "#E1CDE3" }}
  />
}

// this method returns a avatar with a circle that indicates of unread messages
const avatarWithUnreadMessage = (chatSession) => {
  return <Badge
    overlap="circular"
    color="error"
    badgeContent={""}
  >
    {normalAvatar(chatSession)}
  </Badge>
}

/**
 * showing one session item in session list
 * @author Vivian
 * @date 2023-9-8
 */
function ChatSessionItem({ socket, chatSession, friendFilter, handleSessionClick}) {

  const { t } = useTranslation();  //for showing different language text
  const [timeSinceText, setTimeSinceText] = useState(""); //for showing the last message time
  const [refreshSessionList, setRefreshSessionList]=useState(false);  //for refresh chat session list

  {/* A Timer uses to refresh friends session list timeSinceText */}
  const MINUTE_MS = Configration.chatSidebarRefreshMinutes * 60 * 1000; 

  useEffect(() => {
    const interval = setInterval(() => {
      setRefreshSessionList(true);
    }, MINUTE_MS);

    return () => clearInterval(interval); // This represents the unmount function, in which you need to clear your interval to prevent memory leaks.
  }, [])
  {/*Timer End*/}
  

  //for update the last message time when have new message or change the language
  useEffect(() => {
    if (chatSession && chatSession.lastMessageCreatedAt) {
      setTimeSinceText(getTimeSinceText(chatSession.lastMessageCreatedAt));
    }
    setRefreshSessionList(false);
  }, [chatSession.lastMessageCreatedAt, i18next.language, refreshSessionList]);

  //handle this session being click
  function handleClick() {
    handleSessionClick(socket, chatSession);
  }

  //calculate how long has it been since the last message
  function timeSince(date) {
    var seconds = Math.floor((new Date().getTime() - date.getTime()) / 1000);

    var interval = seconds / 31536000;

    if (interval > 1) {
      let value = Math.floor(interval);
      return value + ` year${value === 1 ? "" : "s"} ago`;
    }
    interval = seconds / 2592000;
    if (interval > 1) {
      let value = Math.floor(interval);
      return value + ` month${value === 1 ? "" : "s"} ago`;
    }
    interval = seconds / 86400;
    if (interval > 1) {
      let value = Math.floor(interval);
      return value + ` day${value === 1 ? "" : "s"} ago`;
    }
    interval = seconds / 3600;
    if (interval > 1) {
      let value = Math.floor(interval);
      return value + ` hour${value === 1 ? "" : "s"} ago`;
    }
    interval = seconds / 60;
    if (interval > 1) {
      let value = Math.floor(interval);
      return value + ` minute${value === 1 ? "" : "s"} ago`;
    }
    if (Number.isNaN(seconds) || Math.floor(seconds) < 0) {
      return "a while ago";
    }
    let value = Math.floor(interval);
    if (value == 0) return "just now";
    return value + ` second${value === 1 ? "" : "s"} ago`;
  }

  //change the 'time since' to text
  function getTimeSinceText(createAt) {
    let text = timeSince(new Date(createAt));
    // param looks like this : 2 minutes ago or just now or 3 hours ago or 10 days ago
    let textArr = text.split(" ");

    let map = new Map([
      ["now", t("justnow")],
      ["minute", t("minuteago", { num: textArr[0] })],
      ["minutes", t("minutesago", { num: textArr[0] })],
      ["hour", t("hourago", { num: textArr[0] })],
      ["hours", t("hoursago", { num: textArr[0] })],
      ["day", t("dayago", { num: textArr[0] })],
      ["days", t("daysago", { num: textArr[0] })],
      ["month", t("monthago", { num: textArr[0] })],
      ["months", t("monthsago", { num: textArr[0] })],
      ["year", t("yearago", { num: textArr[0] })],
      ["years", t("yearsago", { num: textArr[0] })],
    ]);

    return map.get(textArr[1]) ?? ""; //?? is a null check operand. If the value is null or undefined then return ""
  }

  return (
    <div style={(friendFilter === "" || chatSession.groupName.toLowerCase().includes(friendFilter.toLowerCase())) ? {display:"block"} : {display:"none"}}>
    <div
      className={
        chatSession.isCurrSession
          ? "conversation clickable selected"
          : "conversation clickable"
      }
      onClick={handleClick}
      key={chatSession._id}
    >
      <div className="conversation-info">
        {/* avatar */}
        {chatSession.hasNewMessage ? avatarWithUnreadMessage(chatSession) : normalAvatar(chatSession)}
        <div>
          {/* friend name or group name */}
          <div style={{ marginLeft: 20, marginBottom:5, fontWeight: 'bold', fontSize:18, color: chatSession.hasNewMessage ? "primary" : "secondary" }}>{chatSession.groupName.length > 18 ? chatSession.groupName.slice(0, 18) + '...' : chatSession.groupName}</div>
          {/* online status */}
          <div className="conversation-status" >
            {chatSession.type === "group" ? t("group") : (chatSession.isOnline ? t("online") : t("offline"))}
            <br />
            {/* how long has it been since the last message */}
            {chatSession.lastMessageId
              ? timeSinceText : null}
          </div>
        </div>
      </div>
    </div>
    </div>
  );
}

export default ChatSessionItem;
