import "../assets/styles/chat.css";

import React, { useEffect, useState, useRef } from "react";
import { useTranslation } from "react-i18next";
import i18next from 'i18next';
import { logEvent } from "firebase/analytics";
import { trace } from "firebase/performance";
import toast from "react-hot-toast";

import { analytics, perf } from '../utils/firebaseAnalytics';
import { getSocket } from "../socket";
import { getCurrSessionId, getLang, getLeftLang, getRightLang, getUser, getUserId, addInpersonMsg, updateInpersonMsg, initInpersonSession } from '../utils/currUser';
import { translate } from "../services/nuchatApi";

import MessaageItem from "./MessageItem";
import ChatHeader from "./ChatHeader";
import InputMsgBar from "./InputMsgBar";
import InpersonHeader from "./InpersonHeader";
import InpersonInputBar from "./InpersonInputBar";

/**
 * message panel for showing the chat header, message list and the input message bar
 * @author Vivian
 * @date 2023-9-10
 */
function MessagePanel({ chatSession, messages, setMessages, updateMessages, setCurrSession, handleOpenAddMemberDialog,
  handleOpenUpdateGroupDescDialog, handleOpenUpdateGroupNameDialog, setErrorCode }) {

  const messageListRef = useRef(null);  //for auto scroll to bottom
  const { t } = useTranslation(); //for showing different language text
  const [currLeftLang, setCurrLeftLang] = useState(getLeftLang() ? getLeftLang() : "en"); // current left person language code, for in-person conversation
  const [currRightLang, setCurrRightLang] = useState(getRightLang() ? getRightLang() : getLang()); // current right person language code, for in-person conversation
  const isDisableSendButton = (chatSession.deletedAt
    || (chatSession.type === "group"
      && (chatSession.groupStatus != "active"
        || (chatSession.groupValidUntil != null && new Date(chatSession.groupValidUntil) < new Date())
        || chatSession.groupAvailableChars == 0)));

  useEffect(() => {
    // if(chatSession._id === "in-person-conversation"){
    setCurrLeftLang(getLeftLang() ? getLeftLang() : "en");
    setCurrRightLang(getRightLang() ? getRightLang() : getLang());
    // }
  }, [i18next.language]);

  // when have the new message, scroll the message list to the bottom
  useEffect(() => {
    scrollToBottom();
  }, [messages.length]);

  //scroll the message list to the bottom
  const scrollToBottom = () => {
    if (messageListRef.current) {
      messageListRef.current.scrollTop = messageListRef.current.scrollHeight;
    }
  };

  // handle sending a new text message
  // type : text(default)/file, for text message, textMsg is the message content, for file message, fileInfo is the file information
  const handleSendMsg = (type, textMsg, fileInfo) => {
    if (chatSession.deletedAt != null) {
      setErrorCode("send_message_disabled_delete");
    } else if (chatSession.type === "group"
      && (chatSession.groupStatus != "active"
        || (chatSession.groupValidUntil != null && new Date(chatSession.groupValidUntil) < new Date()))) {
      setErrorCode("SESSION_STATUS_ERROR");
    } else if (chatSession.type === "group" && chatSession.groupAvailableChars === 0) {
      setErrorCode("GROUP_CHAR_LIMIT_EXCEEDED");
    } else {
      let msg = {
        sessionId: getCurrSessionId(),
      };

      if (type === "file") {
        //record event on firebase
        logEvent(analytics, 'NuCHAT_Chat_SendFile_Click', { sessId: getCurrSessionId(), userId: getUserId().toString() });

        msg.type = "file";
        msg.fileInfo = fileInfo;

      } else {
        //record event on firebase
        logEvent(analytics, 'NuCHAT_Chat_SendMsg_Click', { sessId: getCurrSessionId(), userId: getUserId().toString() });

        msg.type = "text";
        msg.content = textMsg;
        msg.lang = getLang();
      }
      //send message
      const socket = getSocket();
      socket.emit('send_message', msg);
    }
  }

  const handleClear = () => {
    //record event on firebase
    // logEvent(analytics, 'inperson_clear');

    console.log("clear");
    initInpersonSession();
    setMessages([]);
  }

  //handle sending a new message on inperson conversation page
  const handleInpersonSubmit = async (text, position) => {

    if (text.trim().length == 0) { return; }
    const timestamp = new Date().getTime().toString();
    const originalLang = (position === "left") ? getLeftLang() : getRightLang();
    const targetLang = (position === "left") ? getRightLang() : getLeftLang();

    let newMsg = {
      _id: timestamp,
      sessionId: getCurrSessionId(),
      senderId: (position === "left") ? 0 : getUserId(),
      message: {},
      originalLang: originalLang,
      createdAt: new Date(),
    }

    const msgForTranslate = {
      text: text,
      sourceLang: originalLang,
      targetLangs: [targetLang],
    };

    //record event on firebase
    logEvent(analytics, 'NuCHAT_Pronto_SendMessage_Click', { userId: getUserId().toString(), pos: position, lang: originalLang });
    //firebase performance trace
    const tOne = trace(perf, "inperson_translate_one_language");
    const tAll = trace(perf, "inperson_translate_all_language");
    tOne.incrementMetric("textLength", text.length);
    tAll.incrementMetric("textLength", text.length);
    tOne.start();
    tAll.start();

    //translate to other language
    let result = await translate(msgForTranslate);
    tOne.stop();
    if (result.meta.success) {
      newMsg.message = result.data;
      //record event on firebase
      // logEvent(analytics, 'inperson_receive_msg', { messageId: newMsg._id });
      setMessages([...messages, newMsg]);
      console.log("After add message:", messages)
      addInpersonMsg(newMsg);
    }

    //traslate to all language
    delete msgForTranslate.targetLangs;
    result = await translate(msgForTranslate);
    tAll.stop();
    if (result.meta.success) {
      newMsg.message = result.data;
      //record event on firebase
      // logEvent(analytics, 'inperson_update_msg', { messageId: newMsg._id });
      updateMessages(newMsg);
      updateInpersonMsg(newMsg);
    }
  }

  //get the sender's information by sender id
  function getSender(senderId) {
    if (chatSession._id === 'in-person-conversation') {
      const sender = {
        id: senderId,
        fullName: "",
      };
      return sender;
    }
    if (senderId == getUserId()) {
      return getUser();
    }
    if (chatSession && chatSession.membersDetail) {
      let sender = chatSession.membersDetail.find((member) => member.memberId == senderId);
      if (sender) return sender;
    }
    const sender = {
      id: senderId,
      fullName: "",
      avatar: ""
    };
    return sender;
  }

  return (chatSession && chatSession._id && messages && messages.length >= 0) ? (
    <div id="message-panel-container">

      {/* ChatHeader */}
      <div id="message-panel-header">
        {(chatSession._id === 'in-person-conversation') ?
          <InpersonHeader handleClear={handleClear}
            currLeftLang={currLeftLang}
            setCurrLeftLang={setCurrLeftLang}
            currRightLang={currRightLang}
            setCurrRightLang={setCurrRightLang}
          /> :
          <ChatHeader
            chatSession={chatSession}
            setCurrSession={setCurrSession}
            handleOpenAddMemberDialog={handleOpenAddMemberDialog}
            handleOpenUpdateGroupNameDialog={handleOpenUpdateGroupNameDialog}
            handleOpenUpdateGroupDescDialog={handleOpenUpdateGroupDescDialog}
          />}
      </div>

      {/* message list */}
      <div id="message-panel-messages" ref={messageListRef}>
        {
          messages.map((message, index) => {
            const previousMessage = index > 0 ? messages[index - 1] : null;
            return (
              <MessaageItem
                currMessage={message}
                prevMessage={previousMessage}
                sender={getSender(message.senderId)}
                showName={chatSession.type === "group"}
                key={message._id}
                currLeftLang={currLeftLang}
                currRightLang={currRightLang}
              />
            );
          })
        }
      </div>

      {/* input area */}
      <div id="message-panel-input-bar">
        {(chatSession._id === 'in-person-conversation') ?
          <InpersonInputBar placeholderText={t("message_input")} handleSend={handleInpersonSubmit} /> :
          <InputMsgBar
            placeholderText={t("message_input")}
            handleSendMsg={handleSendMsg}
            disabled={isDisableSendButton} />
        }
      </div>
    </div>
  ) : (
    <div id="no-chat-user">{t("choose_conversation")}</div>
  );
}

export default MessagePanel;
