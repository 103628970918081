
import "../assets/styles/chat.css";

import React from 'react';
import { getLeftLang, getRightLang } from "../utils/currUser";

/**
 * message bubble - for showing the content of a message
 * @author Vivian
 * @date 2023-9-15
 */
function InpersonMsgBubble({ message, isSelf, currLeftLang, currRightLang }) {
  const upLang = isSelf ? currLeftLang : currRightLang;
  const downLang = isSelf ? currRightLang : currLeftLang;

  return (
    <div
      style={{
        overflow: "auto",
        width: "100%",
        wordWrap: "break-word",
      }}
    >
      <div
        style={{
          backgroundColor: isSelf ? "#F0b354" : "#A24D84",
          color: isSelf ? "#666666" : "#FFFFFF",
          fontSize: 15,
          fontWeight: 400,
          borderRadius: 20,
          marginTop: 1,
          marginRight: "auto",
          marginBottom: 1,
          marginLeft: "auto",
          maxWidth: "70%",
          paddingTop: 2,
          paddingBottom: 2,
          paddingLeft: 2,
          paddingRight: 2,
          width: "-webkit-fit-content",
          float: isSelf ? "right" : "left",
          justifySelf: isSelf ? "right" : "left",
        }}
      >
          {/* up */}
          <div className={
            (upLang === "ar" || upLang === "fa")
          ? "bubble-msg-up direction-rtl"
          : "bubble-msg-up"
          }>
            {message[upLang]}
          </div>

          {/*  */}
          <hr style={{width:"100%", height:"1px", backgroundColor: "white", color:"white", margin: "0 0"}}/>
          
          {/* down */}
          <div className={
            (downLang === "ar" || downLang === "fa")
          ? "bubble-msg-down direction-rtl"
          : "bubble-msg-down"
          }>
            {message[downLang]}
          </div>

      </div>
    </div>
  );
}

export default InpersonMsgBubble;

