import "../../assets/styles/chat.css"

import React, { useEffect, useState, useRef } from 'react';
import { useTranslation } from "react-i18next";
import { logEvent } from "firebase/analytics";
import Button from "@mui/material/Button";
// import { toast } from 'react-hot-toast';
import { Avatar } from "@mui/material";
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';

import { analytics } from "../../utils/firebaseAnalytics";
import Config from "../../configs/config.json";
import { getAuth, getUser,getUserId, getSettings } from '../../utils/currUser'
import DialogWindowTemplate from "../../utils/DialogWindowTemplate";
import UserService from "../../services/userService";
import StatusItem from "../group/StatusItem";

/**
 * group detail dialog
 * @param {function} props.onClose handle close the dialog
 * @param {Boolean} props.open the dialog is open?
 * @author Vivian
 * @date 2023-11-29
 */
export default function MyProfile(props) {
  const user = getUser();   //user info
  const auth = getAuth();  //user authorization info

  const { t } = useTranslation();  //for showing different language text

  useEffect(() => {
    if (props.open) {
      updateAuth();
    }
  }, [props.open]);

  //update user auth
  const updateAuth = async () => {
    const auth = await UserService.getUserAuth();
  }

  // handle buy button click
  const handleBuyClick = async () => {
    logEvent(analytics, 'NuCHAT_ByNow_Click', {userId: getUserId().toString()}); //record event on firebase
    window.open(getSettings().paymentUrl, '_blank');
  }

  const formattedDate = (dateString) => {
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0'); // month is 0-based
    const day = String(date.getDate()).padStart(2, '0');
  
    return `${year}-${month}-${day}`;
  };

  return (
    <DialogWindowTemplate
      title={user.fullName}
      onClose={props.onClose}
      open={props.open}
      style={{ width: "50px" }}
    >
      {/* user avatar */}
      <div className="dialog-row">
        <Avatar
          style={{
            backgroundColor: "#8F3E97",
            color: "rgb(255, 255, 255, 0.8)",
            height: "90px",
            width: "90px",
            margin: "15px",
          }}
          src={user.avatar ? user.avatar : "n/a"}
          alt={user.fullName}
        />
      </div>

      {/* user name */}
      <div className="dialog-row" style={{ fontWeight: 'bold', fontSize: 18, color: "primary" }}>
        {user.fullName}
      </div>

      {/* user email */}
      <div className="dialog-row" style={{ marginBottom: "20px", fontWeight: 'normal', fontSize: 12, color: "primary" }}>
        {user.email}
      </div>

      {/* user auth */}
      <div className="dialog-row">
        <div id="group-status-list">
          <StatusItem statusName={t("user.available_chars")} statusValue={auth.availableChars} />
          <StatusItem statusName={t("user.voice_package")} statusValue={auth.voicePkg} />
          <StatusItem statusName={t("user.pronto_package")} statusValue={auth.prontoPkg} />
          <StatusItem statusName={t("user.group_package")} statusValue={auth.groupPkg} />
          <StatusItem statusName={t("user.group_member_package")} statusValue={auth.groupMemberPkg} />
          <StatusItem statusName={t("user.group_available_chars")} statusValue={auth.groupAvailableChars} />
          <StatusItem statusName={t("user.end_at")} statusValue={auth.endAt === null ? -1 : formattedDate(auth.endAt)} />
        </div>
      </div>

      {/* button */}
      <div className="dialog-row">
        <Button
          style={{ color: "white", margin: "20px", width: "70%", backgroundColor: "#Af4687", borderRadius: 7 }}
          variant="contained"
          color="primary"
          onClick={handleBuyClick}
        >
          <ShoppingCartIcon className="menu-icon" />{t("payment.link.button")}
        </Button>
      </div>

    </DialogWindowTemplate>
  );
}
