import axios from 'axios';
import toast from 'react-hot-toast';
import i18next from 'i18next';

import configUrl from "../configs/config.json";

const service = axios.create({
  baseURL: configUrl.api.nuchatUrl,
  timeout: 1000 * 10
})

// http request middleware
service.interceptors.request.use(config => {

  let token = localStorage.getItem('token')
  if (token) {
    config.headers = {
      // 'Content-Type': 'application/json',
      'Authorization': token,
      ...config.headers,
    };
  } else {
    config.headers = {
      // 'Content-Type': 'application/json',
      ...config.headers,
    };
  }

  if (!(config.data instanceof FormData)) {
    config.headers['Content-Type'] = 'application/json';
  }else{
    // config.headers['Content-Type'] = 'multipart/form-data';
    delete config.headers['Content-Type'];
  }

  return config;
}, err => {
  return err;
})

// http response middleware
service.interceptors.response.use(res => {
  return res.data;
}, err => {
  if (err && err.response) {
    switch (err.response.status) {
      case 400:
        window.location.href = '/notfound';
        break;
      case 403:
        window.location.href = '/login';
      break;
      case 401:
        window.location.href = '/login';
        break;
      default:
        console.error("other error");
    }
  }
  if(err.code === 'ECONNABORTED') {
    const t = i18next.t;
    // const { t } = useTranslation(); //for showing different language text
    toast.error(t('connect_error'));
    throw err.message;
  } else {
    return err && err.response && err.response.data ? err.response.data : {
      meta: {
        success: false,
        message: 'error'
      }
    };
  }
});

const getMethod = (url = '', params = {}) => {
  return service({
    url,
    method: 'get',
    params: params
  }).catch(err => {
    toast.error(err.meta.message)
    throw err.meta.message
  });
};

const postMethod = (url = '', params = {}) => {
  return service({
    url,
    method: 'POST',
    data: params
  }).catch(err => {
    toast.error(err.meta.message)
    throw err.meta.message
  });
};

const commonMethod = (method = '', url = '', params = null) => {
  if (params) {
    return service({
      url,
      method,
      data: params
    }).catch(err => {
      toast.error(err.meta.message)
      throw err.meta.message
    });
  }

  return service({
    url,
    method,
  }).catch(err => {
    toast.error(err.meta.message)
    throw err.meta.message
  });
};

const execlMethod = (url = '') => {
  return service({
    url,
    method: 'get',
    responseType: 'arraybuffer'
  }).catch(err => {
    toast.error(err.meta.message)
    throw err.meta.message
  });
};

export { service, getMethod as get, postMethod as post, commonMethod as common, execlMethod as execl };
