import "../assets/styles/chat.css";

import { Avatar } from "@mui/material";

import { getCurrSessionId, getLang, getUserId } from "../utils/currUser";

import MessageBubble from '../components/MessageBubble';
import InpersonMsgBubble from '../components/InpersonMsgBubble';

/**
 * message item - for showing all the information about one message
 *              - like: date, time, content
 * @author Vivian
 * @date 2023-9-5
 */
function MessaageItem({ currMessage, prevMessage, sender, showName, currLeftLang, currRightLang }) {

  // compare the date of currMessage and prevMessage, if they are the same date
  function isSameDate() {
    //for first message
    if (!prevMessage) return false;

    let currDate = new Date();
    let prevDate = new Date();
    if (currMessage && currMessage.createdAt)
      currDate = new Date(currMessage.createdAt);
    if (prevMessage && prevMessage.createdAt)
      prevDate = new Date(prevMessage.createdAt);

    return currDate.getFullYear() === prevDate.getFullYear() &&
      currDate.getMonth() === prevDate.getMonth() &&
      currDate.getDate() === prevDate.getDate();
  }

  // compare the time of currMessage and prevMessage, if they were sent within 3 minutes
  function isLinked() {
    //for first message
    if (!prevMessage) return false;

    //for the first message in each date
    if (!isSameDate()) return false;

    let currDate = new Date();
    let prevDate = new Date();
    if (currMessage && currMessage.createdAt)
      currDate = new Date(currMessage.createdAt);
    if (prevMessage && prevMessage.createdAt)
      prevDate = new Date(prevMessage.createdAt);

    return (currDate.getTime() - prevDate.getTime()) / 1000 < 180;
  }

  const formattedDate = (dateString) => {
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0'); // month is 0-based
    const day = String(date.getDate()).padStart(2, '0');
  
    return `${year}-${month}-${day}`;
  };

  return (
    <div style={{
      marginTop: 2,
      marginBottom: 2,
      position: "relative",
      display: "flex",
      flexDirection: "column",
      // border: "1px solid black"
    }}>

      {/* the date */}
      {isSameDate() ? null :
        (<div className="message-date">
          {/* <p>{new Date(currMessage.createdAt).toLocaleDateString()}</p> */}
          <p>{formattedDate(currMessage.createdAt)}</p>
        </div>)}

      {/* the time */}
      {isLinked() ? null :
        (<div style={{
          display: "flex",
          justifyContent: "center",
          color: "#571066",
          paddingLeft: "60px",
          paddingRight: "60px",
          fontSize: "14px",
        }}>
          <p>{new Date(currMessage.createdAt).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', hour12: false })}</p>
        </div>)}
      
      {/* sender name */}
      {showName ?
        <div style={{
          display: "flex",
          justifyContent: currMessage.senderId == getUserId() ? "right" : "left",
          color: "#571066",
          paddingLeft: "60px",
          paddingRight: "60px",
          fontSize: "14px",
        }}>
          {sender.fullName}
        </div>
        : null
      }

      {/* the message with avatar */}
      <div
        style={{
          marginTop: 2,
          marginBottom: 2,
          position: "relative",
          display: "flex",
          flexDirection: "row",
          justifyContent: currMessage.senderId == getUserId() ? "right" : "left",
          width: "100%",
          // border: "1px solid black"
        }}
      >

        {/* Avatar on left */}
        {currMessage.senderId == getUserId() ? (null) : (
          <div>
            <Avatar
              style={{
                backgroundColor: "#A24D84",
                color: "rgb(255, 255, 255, 0.8)",
              }}
              className="avatar bigger"
              src={sender.avatar ? sender.avatar : "n/a"}
              alt={sender.fullName}
            />
          </div>
        )}

        {/* message content */}
        {(getCurrSessionId() === 'in-person-conversation') ?
          <InpersonMsgBubble key={currMessage._id} message={currMessage.message} isSelf={currMessage.senderId == getUserId()} currLeftLang={currLeftLang} currRightLang={currRightLang} />
          : <MessageBubble key={currMessage._id} msgId={currMessage._id} content={currMessage.message[getLang()] || currMessage.message['en']} isSelf={currMessage.senderId == getUserId()} msgType = {currMessage.type} fileInfo = {currMessage.file}/>}

        {/* Avatar on right */}
        {currMessage.senderId == getUserId() ? (
          <div>
            <Avatar
              style={{
                backgroundColor: "#F0b354",
                color: "rgb(255, 255, 255, 0.8)",
              }}
              className="avatar bigger"
              src={sender.avatar ? sender.avatar : "n/a"}
              alt={sender.fullName}
            />
          </div>
        ) : null}
      </div>
    </div>
  );
}

export default MessaageItem;
