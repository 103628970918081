import "../assets/styles/chat.css";

import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Menu, MenuItem } from "@mui/material";

import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import PersonRemoveIcon from '@mui/icons-material/PersonRemove';

import { getUser, getUserId } from "../utils/currUser";
import { useChatSessions, useChatSessionsDispatch } from '../contexts/ChatSessionsContext';
import { getSocket } from "../socket";
import { analytics } from "../utils/firebaseAnalytics";
import { logEvent } from "firebase/analytics";
import ConfirmDialog from "../utils/ConfirmDialog";
import GroupDetailDialog from "./group/GroupDetailDialog";

/**
 * The operation menu for each friend or group
 * @author Vivian
 * @date 2023-11-21
 */
const ContactOperation = ({ chatSession, setCurrSession, handleOpenAddMemberDialog, handleOpenUpdateGroupNameDialog, 
  handleOpenUpdateGroupDescDialog }) => {
  const chatSessions = useChatSessions(); //[{chatSession}], all chat sessions array
  const chatSessionsDispatch = useChatSessionsDispatch(); // for edit chatSessions

  let user = getUser(); //user information
  const [operationAnchor, setOperationAnchor] = useState(null);
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false); //is the confirm delete dialog open
  const [openGroupDetail, setOpenGroupDetail] = useState(false); //is the group detail dialog open
  const { t } = useTranslation(); //for showing different language text
  let socket = getSocket();

  //   const friendName = chatSession.groupName;

  //open the operation list
  function handleMenuOpen(menu) {
    setOperationAnchor(menu.currentTarget);
  }

  //close the operation list
  function handleMenuClose() {
    setOperationAnchor(null);
  }

  //On clicke "delete friend", Open the delete confirm dialog
  const handleDeleteFriendClick = () => {
    //record event on firebase
    logEvent(analytics, "NuCHAT_deleteFriend_Submit", { userId: `${user.id}`, sessId: chatSession._id });

    setOpenConfirmDialog(true);
    handleMenuClose();
  };

  //Close the delete confirm dialog
  const handleConfirmDialogClose = () => {
    setOpenConfirmDialog(false);
  };

  //Close the dialog box and doing some works
  const handleConfirmOperation = () => {
    setOpenConfirmDialog(false);  //close the dialog box
    handleDeleteFriend(chatSession);  //send delete socket to the server
    // handleDeleteFriend(chatSession); //Click event to delete friend
  };

  //handle delete by sessionId - delete friend or group
  const handleDeleteFriend = (chatSession) => {
    if (chatSession.deletedAt) { // if firend already deleted, remove the history
      //update the chatSession context
      chatSessionsDispatch({
        type: "sessionDeleted",
        session: chatSession,
        isRemove: true,
      });
      setCurrSession({}); //when currSession is empty, the system will automatically change to the first session
    } else {
      //record event on firebase
      // logEvent(analytics, "NuCHAT_deleteFriend_Submit", { userId: `${user.id}`, sessId: chatSession._id }); //record event on firebase

      //send deleted request
      socket.emit("delete_session", chatSession._id);
    }
  };

  //On clicke "group detail", Open the group detail dialog
  const handleGroupDetailClick = () => {
    //record event on firebase
    logEvent(analytics, 'NuCHAT_Group_Detail_Click', { userId: getUserId().toString(), sessId: chatSession._id });

    setOpenGroupDetail(true);
    handleMenuClose();
  };

  //Close the delete confirm dialog
  const handleGroupDialogClose = () => {
    setOpenGroupDetail(false);
  };

  return (
    <div>
      {/* Three dots */}
      {/* TODO:  use this condition for close group detail feature. */}
      {/* {chatSession.type === "group" ? null :
      <MoreHorizIcon
        onClick={handleMenuOpen}
        sx={{ cursor: "pointer" }}
      ></MoreHorizIcon>} */}
      <MoreHorizIcon
        onClick={handleMenuOpen}
        sx={{ cursor: "pointer" }}
      ></MoreHorizIcon>

      {/* operation list */}
      <Menu
        id="contact-operation-menu"
        anchorEl={operationAnchor}
        keepMounted
        open={Boolean(operationAnchor)}
        onClose={handleMenuClose}
      >
        {chatSession.type === "group" ? <MenuItem key="group-menu" onClick={handleGroupDetailClick}>
          <ManageAccountsIcon className="menu-icon" />{t("group.menu.detail")}
        </MenuItem>
          : <MenuItem key="friend-menu" onClick={handleDeleteFriendClick}>
            <PersonRemoveIcon className="menu-icon" />{t("delete_session")}
          </MenuItem>}

      </Menu>

      {/* Open dialog box to confirm if delete the friend*/}
      <ConfirmDialog
        openDialog={openConfirmDialog}
        title={t("delete_session.confirm_dialog.title")}
        notice={t("delete_session.confirm_dialog.text", { friendName: chatSession.groupName })}
        cancelButtonName={t("delete_session.confirm_dialog.cancel")}
        confirmButtonName={t("delete_session.confirm_dialog.confirm")}
        handleClose={handleConfirmDialogClose}
        handleConfirm={handleConfirmOperation}
      ></ConfirmDialog>

      {/* group detail dialog */}
      <GroupDetailDialog open={openGroupDetail} onClose={handleGroupDialogClose} chatSession={chatSession} handleOpenAddMemberDialog={handleOpenAddMemberDialog} 
      handleOpenUpdateGroupNameDialog={handleOpenUpdateGroupNameDialog} handleOpenUpdateGroupDescDialog={handleOpenUpdateGroupDescDialog}></GroupDetailDialog>

    </div>
  );
};

export default ContactOperation;
