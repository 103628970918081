import { useState } from 'react';
import { getSettings } from './currUser';

const useFormInput = (initialValue, maxLength, handleSubmit) => {
  const [value, setValue] = useState(initialValue);
  const settings = getSettings();
  maxLength = maxLength || settings.msgLimitChars;

  const handleChange = e => {
    // verify the length of the text
    const newValue = e.target.value;
    if (newValue.length > maxLength) {
      setValue(newValue.slice(0, maxLength));
    } else {
      setValue(newValue);
    }
  };

  const handleKeyPress = e => {
    if (handleSubmit) {
      if (e.keyCode === 13) {
        e.preventDefault();
        handleSubmit();
      }
    }
  }

  const clearValue = () => {
    setValue("");
  }

  return {
    value,
    onChange: handleChange,
    onKeyDown: handleKeyPress,
    clearValue: clearValue,
  };
};

export default useFormInput;