import "../assets/styles/chat.css";

import { useTranslation } from "react-i18next";
import React, { useState } from 'react';
import ReactLoading from 'react-loading';
import toast from "react-hot-toast";
import TextField from '@mui/material/TextField';
import { outlinedInputClasses } from "@mui/material/OutlinedInput";
// import { inputLabelClasses } from "@mui/material/InputLabel";
import { styled } from "@mui/material/styles";
import IconButton from '@mui/material/IconButton';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos'
import AttachFileIcon from '@mui/icons-material/AttachFile';

import { uploadFileMsg } from '../services/nuchatApi';
import useFormInput from '../utils/useFormInput';
import { getCurrSessionId, getSettings } from '../utils/currUser';
import configInfo from '../configs/config.json';

const StyledTextField = styled(TextField)({
  [`& fieldset`]: {
    borderRadius: 45,
  },
  [`& .${outlinedInputClasses.root} .${outlinedInputClasses.notchedOutline}`]: {
    borderColor: "#A24D84",
    // border: "none"
  },
  [`&:hover .${outlinedInputClasses.root} .${outlinedInputClasses.notchedOutline}`]: {
    borderColor: "#A24D84",
    // border: "none"
  },
  [`& .${outlinedInputClasses.root}.${outlinedInputClasses.focused} .${outlinedInputClasses.notchedOutline}`]: {
    borderColor: "#A24D84",
    // border: "none"
  },
  // [`& .${outlinedInputClasses.input}`]: {
  //   color: "green"
  // },
  // [`&:hover .${outlinedInputClasses.input}`]: {
  //   color: "red"
  // },
  // [`& .${outlinedInputClasses.root}.${outlinedInputClasses.focused} .${outlinedInputClasses.input}`]: {
  //   color: "purple"
  // },
  // [`& .${inputLabelClasses.outlined}`]: {
  //   color: "green"
  // },
  // [`&:hover .${inputLabelClasses.outlined}`]: {
  //   color: "red"
  // },
  // [`& .${inputLabelClasses.outlined}.${inputLabelClasses.focused}`]: {
  //   color: "purple"
  // }
});

/**
 * input message bar - for sending new message
 * @author Vivian
 * @date 2023-9-13
 */
function InputMsgBar({ placeholderText, handleSendMsg, disabled }) {
  const { t } = useTranslation(); //for showing different language text
  const [isLoading, setIsLoading] = useState(false);

  const settings = getSettings();
  const inputMsg = useFormInput('', settings.msgLimitChars, handleSubmit);  //the inputed message
  const title = disabled ? t("send_message_disabled_delete") : t("send_message");

  //handle submit the message
  async function handleSubmit() {
    handleSendMsg("text", inputMsg.value, null);
    inputMsg.clearValue();
  };

  //handle upload file
  function handleUploadFile() {
    const input = document.createElement('input');
    input.type = 'file';
    input.accept = settings.allowedMimeTypes;
    input.onchange = async (e) => {
      const file = e.target.files[0];

      // check file size
      if (file.size > settings.maxFileSize) {
        toast.error(t("FILE_SIZE_ERROR"));
        return;
      }

      if (file) {
        setIsLoading(true);
        try {
          let res = await uploadFileMsg(getCurrSessionId(), file)
          if (res && res.data && res.data.url) {
            handleSendMsg("file", res.data.fileName, res.data);
          } else {
            toast.error(t("upload_file.error"));
          }
        } catch (error) {
          toast.error(t("upload_file.error"));
        } finally {
          setIsLoading(false);
        }
      }
    };
    input.click();
  }

  return (
    <div className="input-msg-bar">
      <StyledTextField
        variant="outlined"
        style={{ width: "100%" }}
        multiline
        value={inputMsg.value} 
        onChange={inputMsg.onChange} 
        onKeyDown={inputMsg.onKeyDown}
        // {...inputMsg}
        placeholder={placeholderText}
        InputProps={{
          startAdornment: (
            <IconButton style={{ paddingLeft: 7, paddingRight: 7}} onClick={handleUploadFile}>
              <AttachFileIcon style={{ fill: "#513695"}} />
            </IconButton>
          ),
          endAdornment: (
            <IconButton
              color="primary"
              aria-label="send"
              component="span"
              style={{
                height: "4vh",
                width: "4vh",
                marginLeft: "1vh",
                background: disabled ? "#cccccc" : "linear-gradient(#c34599, #523694)",
                color: "white",
              }}
              onClick={handleSubmit}
              title={title}
            >
              <ArrowForwardIosIcon />
            </IconButton>
          ),
        }}
      />
      {isLoading ?
        <div style={{ position: "fixed", top: 0, left: 0, width: "100%", height: "100%", display: "flex", justifyContent: "center", alignItems: "center", zIndex: "999" }}>
          <ReactLoading type="spinningBubbles" color="purple" width={100} height={100} />
        </div> : null
      }
    </div>
  );
}

export default InputMsgBar;
