import React from 'react';
import { useTranslation } from 'react-i18next';
import Button from '@mui/material/Button';

const NotFound = () => {
  const { t } = useTranslation(); //for showing different language text

  // handle lgoin button click
  const handleLoginClick = async () => {
    window.location.href = '/login';
  }

  return (
    <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", height: "100vh", flexWrap: "wrap" }}>
      <div className='error_title'>{t('PAGE_NOT_FOUND')} </div>
      <img className="error_img" src="/images/not_found.png" />
      <div className='error_text'>{t('PAGE_NOT_FOUND_INFO')}</div>
      <div>
        {/* login button */}
        <Button
          style={{ color: "white", fontWeight: "bold", backgroundColor: "#501662", borderRadius: 7, width: "20vh", height: "6vh", marginTop: "20px" }}
          variant="contained"
          color="primary"
          onClick={handleLoginClick}
        >
          {t('login')}
        </Button>
      </div>
    </div>
  );
}

export default NotFound;