import { getSocket } from '../socket';
import { getCurrSessionId, getUserId, setCurrSessionId, setSettings } from '../utils/currUser';
import { getAllSessionsApi, getAllMsgBySessIdApi, getSessionBasicInfoApi, uploadGroupAvatarApi, getConfigsApi } from './nuchatApi';
import configUrl from "../configs/config.json";

class ChatService {

    constructor() {
    }

    //get init information from server
    async initContent() {
        //get settings
        const configs = await getConfigsApi();
        setSettings(configs.data);

        //get chat sessions
        let result = await getAllSessionsApi();
        console.log(`getChatSessionsApi result: ${result}`);
        let chatSessions = result.data;

        //update each chat session
        for (let index = 0; index < chatSessions.length; index++) {
            let chatSession = chatSessions[index];
            const currSessId = getCurrSessionId();
            if (!currSessId && index == 0) {
                // default set the first(latest) chat session as the current selected session
                chatSession.isCurrSession = true;
                setCurrSessionId(chatSession._id);
                const socket = getSocket();
                socket.emit('read_messages', chatSession._id); //send 'change_curr_session' event to server
            } else if (currSessId && currSessId === chatSession._id) {
                //set the isCurrSession same with the old currentSession
                chatSession.isCurrSession = true;
            } else {
                chatSession.isCurrSession = false;
            }

            await this.initSession(chatSession);
        }

        return chatSessions;
    }

    async initSession(chatSession) {
        // traverse memberDetail
        for (let i = 0; i < chatSession.membersDetail.length; i++) {
            const member = chatSession.membersDetail[i];

            if (member.memberId == getUserId()) {
                //set is there any new message
                if (chatSession.lastMessageId && (member.lastSeenMessageId != chatSession.lastMessageId)) {
                    chatSession.hasNewMessage = true;
                } else {
                    chatSession.hasNewMessage = false;
                }
                chatSession.lastSeenMessageId = member.lastSeenMessageId;
                chatSession.lastSeenMessageAt = member.lastSeenMessageAt;
            } else {
                if (chatSession.type !== "group") {
                    //set friendId
                    chatSession.friendId = member.memberId;
                    //set friend is online
                    chatSession.isOnline = false;
                    //update groupName
                    chatSession.groupName = member.fullName;
                    //update groupAvatar
                    chatSession.groupAvatar = member.avatar;
                } else {
                    chatSession.friendId = null;
                    chatSession.isOnline = false;
                }
            }
        }

        //update groupAvatar
        if (chatSession.type === "group") {
            chatSession.groupAvatar = configUrl.api.nuchatUrl + "/" + chatSession.groupAvatar;
        }

        // get messages for each chat session
        const response = await getAllMsgBySessIdApi(chatSession._id);
        const messages = response.data ? response.data : [];
        chatSession.messages = messages;

        return chatSession;
    }

    //get session getGroupAvailableChars
    async getGroupAvailableChars(sessionId) {
        const result = await getSessionBasicInfoApi(sessionId);
        if (result && result.meta.success && result.data.groupAvailableChars) {
            return result.data.groupAvailableChars;
        } else {
            return 0;
        }

    }

    //upload group avatar
    async uploadGroupAvatar(groupId, avatarFile) {
        const result = await uploadGroupAvatarApi(groupId, avatarFile);
        if (result && result.meta.success && result.data) {
            return result.data;
        } else {
            return "";
        }
    }
}

export default new ChatService();