import '../../assets/styles/chat.css'

import React, { useState } from 'react';
import { Avatar } from "@mui/material";
import { useTranslation } from "react-i18next";
import { logEvent } from "firebase/analytics";

import { analytics } from "../../utils/firebaseAnalytics";
import IconButton from '@mui/material/IconButton';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import ConfirmDialog from "../../utils/ConfirmDialog";
import { getUserId } from '../../utils/currUser';

// a avatar for chat sessions sidebar
const normalAvatar = (member) => {
  return <Avatar
    className="avatar.small"
    alt={member.fullName}
    src={member.avatar ? member.avatar : "n/a"}
    style={{ backgroundColor: "#E1CDE3", width: "40px", height: "40px" }}
  />
}

/**
 * showing one member item in member list
 * @author Vivian
 * @date 2023-11-21
 */
function MemberItem({ socket, member, memberFilter, admins, isCurrUserAdmin }) {

  const { t } = useTranslation();  //for showing different language text
  const [openRemoveMemDialog, setOpenRemoveMemDialog] = useState(false); //is the confirm remove member dialog open
  const [openAddAdminDialog, setOpenAddAdminDialog] = useState(false); //is the confirm add an admin dialog open
  const [openRemoveAdminDialog, setOpenRemoveAdminDialog] = useState(false); //is the confirm remove an admin dialog open
  const isSelf = (member.memberId === getUserId()); // is this member is the current user
  const isAdmin = admins.includes(member.memberId); // is this member is an admin

  //On clicke "remove group member", Open the confirm remove dialog
  const onClickRemoveMember = () => {
    //record event on firebase
    logEvent(analytics, "NuCHAT_Group_RemoveMem_Click", { userId: getUserId().toString(), memId:member.memberId, sessId:member.sessionId });

    setOpenRemoveMemDialog(true);
  };

  //Close the confirm remove dialog
  const handleRemoveMemDialogClose = () => {
    setOpenRemoveMemDialog(false);
  };

  //Close the confirm remove dialog and send remove socket event to the server
  const handleRemoveMemOperation = () => {
    setOpenRemoveMemDialog(false);  //close the confirm remove dialog
    //send remove member request
    socket.emit("remove_group_member", {memberId:member.memberId, groupId:member.sessionId});
  };

  //On clicke "promot a member to an admin", Open the confirm dialog for add an admin
  const onClickAddAdmin = () => {
    //record event on firebase
    logEvent(analytics, "NuCHAT_Group_AddAdmin_Click", { userId: getUserId().toString(), memId:member.memberId, sessId:member.sessionId });
    setOpenAddAdminDialog(true);
  };

  //Close the confirm remove dialog
  const handleAddAdminDialogClose = () => {
    setOpenAddAdminDialog(false);
  };

  //Close the confirm remove dialog and send remove socket event to the server
  const handleAddAdminOperation = () => {
    setOpenAddAdminDialog(false);  //close the confirm remove dialog
    //send remove member request
    socket.emit("add_group_admin", {memberId:member.memberId, groupId:member.sessionId});
  };

  //On clicke "promot a member to an admin", Open the confirm dialog for add an admin
  const onClickRemoveAdmin = () => {
    //record event on firebase
    logEvent(analytics, "NuCHAT_Group_RemoveAdmin_Click", { userId: getUserId().toString(), memId:member.memberId, sessId:member.sessionId });
    setOpenRemoveAdminDialog(true);
  };

  //Close the confirm remove dialog
  const handleRemoveAdminDialogClose = () => {
    setOpenRemoveAdminDialog(false);
  };

  //Close the confirm remove dialog and send remove socket event to the server
  const handleRemoveAdminOperation = () => {
    setOpenRemoveAdminDialog(false);  //close the confirm remove dialog
    //send remove member request
    socket.emit("remove_group_admin", {memberId:member.memberId, groupId:member.sessionId});
  };

  return (
    // only show match the filter search text and not deleted member
    <div style={((memberFilter === "" || member.fullName.toLowerCase().includes(memberFilter.toLowerCase())) && member.deletedAt === null )? { display: "block" } : { display: "none" }}>
      <div
        className="group-member-item"
        key={member._id}
      >
        <div className="group-member-info">
          {/* avatar */}
          {normalAvatar(member)}
          <div>
            {/* member's name*/}
            <div style={{ marginLeft: 20, marginBottom: 5, fontWeight: 'bold', fontSize: 16 }}>{member.fullName}</div>
          </div>
        </div>
        <div>
          {/*showing is this member an admin, also for promot an admin or remove an admin*/}
          {(isAdmin) ?
            <IconButton
              style={{
                height: "30px",
                width: "30px",
                color: "#Af4687",
              }}
              title={t("group.remove_admin")}
              onClick={isCurrUserAdmin ? onClickRemoveAdmin : null}
            >
              <ManageAccountsIcon />
            </IconButton>
            : 
             isCurrUserAdmin ? <IconButton
              style={{
                height: "30px",
                width: "30px",
                color: "#cccccc",
              }}
              title={t("group.add_admin")}
              onClick={isCurrUserAdmin ? onClickAddAdmin : null}
            >
              <ManageAccountsIcon />
            </IconButton> : null 
            }

          {/*remove member icon button - only show to group admin*/}
          {(isCurrUserAdmin && !isSelf) ?
            <IconButton
              style={{
                height: "30px",
                width: "30px",
                color: "#Af4687",
              }}
              title={t("group.remove_member")}
              onClick={onClickRemoveMember}
            >
              <RemoveCircleIcon />
            </IconButton>
            : null}
        </div>
      </div>

      {/* Open dialog box to confirm if remove the member*/}
      <ConfirmDialog
        openDialog={openRemoveMemDialog}
        title={t("group.remove_member")}
        notice={t("group.remove_member.confirm_dialog.text", { memberName: member.fullName })}
        cancelButtonName={t("group.remove_member.confirm_dialog.cancel")}
        confirmButtonName={t("group.remove_member.confirm_dialog.confirm")}
        handleClose={handleRemoveMemDialogClose}
        handleConfirm={handleRemoveMemOperation}
      ></ConfirmDialog>

      <ConfirmDialog
        openDialog={openAddAdminDialog}
        title={t("group.add_admin")}
        notice={t("group.add_admin.confirm_dialog.text", { memberName: member.fullName })}
        cancelButtonName={t("group.add_admin.confirm_dialog.cancel")}
        confirmButtonName={t("group.add_admin.confirm_dialog.confirm")}
        handleClose={handleAddAdminDialogClose}
        handleConfirm={handleAddAdminOperation}
      ></ConfirmDialog>

      <ConfirmDialog
        openDialog={openRemoveAdminDialog}
        title={t("group.remove_admin")}
        notice={t("group.remove_admin.confirm_dialog.text", { memberName: member.fullName })}
        cancelButtonName={t("group.remove_admin.confirm_dialog.cancel")}
        confirmButtonName={t("group.remove_admin.confirm_dialog.confirm")}
        handleClose={handleRemoveAdminDialogClose}
        handleConfirm={handleRemoveAdminOperation}
      ></ConfirmDialog>
    </div>
  );
}

export default MemberItem;
