import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';
// import { getToken } from './currUser';

// handle the public routes
const PublicRoutes = ({isLoggedIn, isAuthed}) => {
  console.log(`[PublicRoutes]: isLoggedIn:${isLoggedIn}, isAuthed: ${isAuthed}`);
  return (!isLoggedIn || !isAuthed) ? <Outlet /> : <Navigate to="/chat" />
}

export default PublicRoutes;