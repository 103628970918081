import DialogWindowTemplate from "../utils/DialogWindowTemplate";
import QRCode from "react-qr-code";

export default function QRCodeDialog(props) {
  return (
    <DialogWindowTemplate
      title={props.title}
      onClose={props.onClose}
      open={props.open}
      subTitle={props.subTitle}
    >
      <QRCode
        size={256}
        style={{ height: "auto", maxWidth: "100%", width: "100%" }}
        value={props.QRCodeStr}
        viewBox={`0 0 256 256`}
      />
    </DialogWindowTemplate>
  );
}
