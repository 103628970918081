import '../../assets/styles/chat.css'

import React, { useState } from 'react';
import { Avatar } from "@mui/material";
import { useTranslation } from "react-i18next";
import IconButton from '@mui/material/IconButton';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { logEvent } from "firebase/analytics";

import { analytics } from "../../utils/firebaseAnalytics";
import ConfirmDialog from "../../utils/ConfirmDialog";
import { getUserId } from '../../utils/currUser';

// a avatar for chat sessions sidebar
const normalAvatar = (chatSession) => {
  return <Avatar
    className="avatar"
    alt={chatSession.groupName}
    src={chatSession.groupAvatar ? chatSession.groupAvatar : "n/a"}
    style={{ backgroundColor: "#E1CDE3" }}
  />
}

/**
 * showing one group item in group list
 * @author Vivian
 * @date 2024-04-15
 */
function GroupItem({ socket, group, isShowDeleteIcon }) {

  const { t } = useTranslation();  //for showing different language text
  const [openConfirmDeleteDialog, setOpenConfirmDeleteDialog] = useState(false); //is the confirm delete dialog open

  //On clicke add friend
  const onClickJoinGroup = () => {
    logEvent(analytics, "NuCHAT_Group_Join_Submit", { userId: getUserId().toString() }); //record event on firebase
    socket.emit("scan_qrcode", group.groupQRCode);
  };

  // //On clicke "delete friend", Open the delete confirm dialog
  // const onClickDeleteFriend = () => {
  //   //record event on firebase
  //   // logEvent(analytics, "NuCHAT_deleteFriend_Submit", { userId: `${user.id}`, sessId: chatSession._id });
  //   // setOpenConfirmDeleteDialog(true);
  // };

  // //Close the delete confirm dialog
  // const handleConfirmDialogClose = () => {
  //   // setOpenConfirmDeleteDialog(false);
  // };

  // //Close the dialog box and doing some works
  // const handleConfirmOperation = () => {
  //   // setOpenConfirmDeleteDialog(false);  //close the dialog box
  //   // handleDeleteFriend(chatSession);  //send delete socket to the server
  //   // handleDeleteFriend(chatSession); //Click event to delete friend
  // };

  // //handle delete by sessionId - delete friend or group
  // const handleDeleteFriend = (chatSession) => {
  //   //record event on firebase
  //   // logEvent(analytics, "NuCHAT_deleteFriend_Submit", { userId: `${user.id}`, sessId: chatSession._id }); //record event on firebase

  //   //send deleted request
  //   // socket.emit("delete_session", chatSession._id);
  // };

  return (
    <div style={{ display: "block" }}>
      <div
        className="user-item"
        key={group.id}
      >
        <div className="user-info">
          {/* avatar */}
          {normalAvatar(group)}
          <div>
            {/* member's name*/}
            <div style={{ marginLeft: 20, marginBottom: 5, fontWeight: 'bold', fontSize: 16 }}>{group.groupName}</div>
          </div>
        </div>
        <div>
          {/*add friend button - only show when user is not your friend*/}
          {(!group.isMember) ?
            <IconButton
              style={{
                height: "30px",
                width: "30px",
                color: "#Af4687",
              }}
              title={t("group.join_group.submit")}
              onClick={onClickJoinGroup}
            >
              <AddCircleIcon />
            </IconButton>
            :
            isShowDeleteIcon ?
              <IconButton
                style={{
                  height: "30px",
                  width: "30px",
                  color: "#Af4687",
                }}
                title={t("group.remove_member")}
                onClick={() => { }}
              >
                <RemoveCircleIcon />
              </IconButton> :
              <div style={{
                fontSize: 14,
                color: "gray",
                fontWeight: 200
              }}>{t("friend.status.added")}</div>
          }
        </div>
      </div>

      {/* Open dialog box to confirm if delete the friend*/}
      {/* <ConfirmDialog
        openDialog={openConfirmDeleteDialog}
        title={t("delete_session.confirm_dialog.title")}
        notice={t("delete_session.confirm_dialog.text", { friendName: group.groupName })}
        cancelButtonName={t("delete_session.confirm_dialog.cancel")}
        confirmButtonName={t("delete_session.confirm_dialog.confirm")}
        handleClose={handleConfirmDialogClose}
        handleConfirm={handleConfirmOperation}
      ></ConfirmDialog> */}
    </div>
  );
}

export default GroupItem;
