import "../assets/styles/chat.css";

import React from "react";

import ContactOperation from "./ContactOperation";

/**
 * message panel's header for showing the user's name or group name
 * @author Vivian
 * @date 2023-9-10
 */
function ChatHeader({ chatSession, setCurrSession, handleOpenAddMemberDialog, handleOpenUpdateGroupNameDialog, handleOpenUpdateGroupDescDialog }) {

  return (
    // <div style={{ display: "flex", justifyContent: "space-between", alignItems:"center"}}></div>
    <div style={{
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      backgroundColor: "transparent",
      border: "none",
      paddingLeft: "20px",
      padding: "15px",
    }}>
      <div style={{
        fontWeight: "bold",
        fontSize: "24px",
        color: "#501662",
        textAlign: "left",
      }}>
        {chatSession.groupName}
        {chatSession.type === "group" ? ` ( ${chatSession.members.length} )` : null}
      </div>
      <div style={{ textAlign: "right" }}><ContactOperation chatSession={chatSession} setCurrSession={setCurrSession}
        handleOpenAddMemberDialog={handleOpenAddMemberDialog} handleOpenUpdateGroupNameDialog={handleOpenUpdateGroupNameDialog}
        handleOpenUpdateGroupDescDialog={handleOpenUpdateGroupDescDialog}></ContactOperation></div>
    </div>
  );
}

export default ChatHeader;
