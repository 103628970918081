import React from 'react';
import { useTranslation } from 'react-i18next';
import Button from '@mui/material/Button';
import { logEvent } from "firebase/analytics";

import { analytics } from "../utils/firebaseAnalytics";
import { getUserId, getSettings } from '../utils/currUser'
import Config from "../configs/config.json";

const NotAuth = () => {
  const { t } = useTranslation(); //for showing different language text

    // handle buy button click
    const handleBuyClick = async () => {
      logEvent(analytics, 'NuCHAT_ByNow_Click', {userId: getUserId().toString()}); //record event on firebase
      window.open(getSettings().paymentUrl, '_blank');
    }

    // handle lgoin button click
    const handleLoginClick = async () => {
      window.location.href='/login';
    }


  return (
    <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", height: "100vh", flexWrap: "wrap" }}>
      <div className='error_title'> </div>
      <img className="error_img" src="/images/no_auth.png" />
      <div className='error_text'>{t('PRODUCT_AUTH_ERROR.WEB.INFO')}</div>
      <div>
        {/* login button */}
        <Button
          style={{ color: "white", fontWeight: "bold", backgroundColor: "#F0b354", borderRadius: 7, width: "20vh", height: "6vh", marginTop: "20px", marginRight: "15px" }}
          variant="contained"
          color="primary"
          onClick={handleLoginClick}
        >
          {t('login')}
        </Button>

        {/* buy button */}
        <Button
          style={{ color: "white", fontWeight: "bold", backgroundColor: "#501662", borderRadius: 7, width: "20vh", height: "6vh", marginTop: "20px", marginLeft: "15px" }}
          variant="contained"
          color="primary"
          onClick={handleBuyClick}
        >
          {t('payment.link.button')}
        </Button>
      </div>
    </div>
  );

}

export default NotAuth;