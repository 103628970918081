import { io } from 'socket.io-client';
import { getToken, getLang, getCurrSessionId } from "./utils/currUser";
import configUrl from "./configs/config.json";



let socket = null;

/**
 * For init the websocket
 * @author Vivian
 * @Date 2023-08-30
 */
export const initSocket = () => {
  socket = io(configUrl.api.nuchatSocketUrl, {
    autoConnect: false,
    reconnection: true,
    reconnectionAttempts: 1,
    reconnectionDelay: 1000,
    query: {
      token: getToken(),
      lang: getLang(),
      // currSessionId: getCurrSessionId() === "in-person-conversation" ? null : getCurrSessionId(),
    },
  });
};

export const getSocket = () => {
  if (!socket) {
    initSocket();
  }
  return socket;
};
