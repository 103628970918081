import "../assets/styles/chat.css"

import React, { useState, useEffect } from 'react';
import { useTranslation } from "react-i18next";
import { useForm, Controller } from 'react-hook-form';
// import { ThemeProvider } from '@mui/material/styles';
// import { TextField } from "@mui/material";
// import Button from "@mui/material/Button";
// import DialogActions from "@mui/material/DialogActions";
// import DialogContent from "@mui/material/DialogContent";
import { logEvent } from "firebase/analytics";

import { analytics } from "../utils/firebaseAnalytics";
import { getSocket } from "../socket";
import DialogWindowTemplate from "../utils/DialogWindowTemplate";
// import customTheme from "../utils/customTheme";
import { getUserId } from '../utils/currUser';
import UserItem from "./UserItem";
import SearchUserBar from "./SearchUserBar";
import { searchUser } from "../services/nuchatApi";

/**
 * Add friend dialog
 * @param {function} props.onClose handle close the dialog
 * @param {Boolean} props.open the dialog is open?
 * @author Vivian
 * @date 2024-04-15
 */
export default function AddFriendDialog(props) {
  const socket = getSocket();
  const { t } = useTranslation();  //for showing different language text
  const { handleSubmit, control, setError, clearErrors, setValue, formState: { errors } } = useForm();
  const [userList, setUserList] = useState([]);

  useEffect(() => {
    clearErrors('friendInfo');
    setValue('friendInfo', '');
    setUserList([]);
    // if (props.open) {
    //   textFieldRef.current?.focus?.();
    // }
  }, [props.open]);

  const handleSearch = async (userInfo) => {
    if (!userInfo) {
      return;
    }
    logEvent(analytics, "NuCHAT_SearchFriend_Submit", { userId: getUserId().toString() });//record event on firebase
    const result = await searchUser(userInfo);
    if (!result.data) {
      setUserList([]);
      return;
    }
    setUserList([result.data]);
  };

  const onClose = () => {
    clearErrors('friendInfo');
    setValue('friendInfo', '');
    props.onClose();
  }

  return (
    <DialogWindowTemplate
      title={t("add_friend")}
      onClose={onClose}
      open={props.open}
    >
      <div>

        {/* Search Bar */}
        <div className="dialog-input">
          <SearchUserBar
            placeholderText={t("user.search_info")}
            handleSearch={handleSearch}
          ></SearchUserBar>
        </div>
{/* 
        <form onSubmit={handleSubmit(handleSearch)}>
          <DialogContent>
            <div>
              <Controller
                name="friendInfo"
                control={control}
                rules={{ required: t('user.search_info.required') }}
                render={({ field }) => (

                  <ThemeProvider theme={customTheme}>
                    <TextField
                      {...field}
                      label={t('user.search_info')}
                      variant="outlined"
                      error={Boolean(errors.friendInfo)}
                      helperText={errors.friendInfo?.message}
                      style={{ width: '100%' }}
                      // inputRef={textFieldRef}
                      autoFocus
                    />
                  </ThemeProvider>

                )}
              />
            </div>
          </DialogContent>
          <DialogActions>
            <Button style={{ fontWeight: "bold", color: "#513695" }} onClick={onClose}>
              {t('button.cancel')}
            </Button>
            <Button type="submit" style={{ fontWeight: "bold", backgroundColor: "#f0dbf2", color: "#513695" }} >
              {t('add_friend.submit')}
            </Button>
          </DialogActions>
        </form> */}
      </div>
      {/* user list */}
      <div className="dialog-row">
        <div id="user-list" style={{ width: "45vh", padding: "10px" }}>
          {userList.map((user) => {
            return (
              <UserItem
                socket={socket}
                user={user}
                key={user.id}
                isShowDeleteIcon={false}
              />
            );
          })}
        </div>
      </div>

    </DialogWindowTemplate>
  );
}
