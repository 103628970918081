import { getToken, getLang, setUserSession, removeUserSession, setAuthSession, removeAuthSession, clear } from '../utils/currUser';
import { getNuChatAuthApi, loginApi } from './nuchatApi';
import { getSocket } from '../socket';

class UserService {
  constructor() {
    this.setIsLoggedIn = null;
    this.setIsAuthed = null;
  }

  init(setIsLoggedIn, setIsAuthed) {
    this.setIsLoggedIn = setIsLoggedIn;
    this.setIsAuthed = setIsAuthed;
  }

  // For socket check user auth
  authError() {
    this.setIsAuthed(false);
  }

  // Verify user
  async verifyUser() {
    let result = false;
    try {
      //for get auth
      const token = getToken();
      if (!token) {
        removeUserSession();
        this.setIsLoggedIn(false);
        this.setIsAuthed(false);
        console.log(`[USERSERVICE.verifyUser] Token not exist.`);
        return result;
      }

      console.log(`[USERSERVICE.verifyUser] Token exist, go to getAuth`);
      //clean the old auth info
      removeAuthSession();
      this.setIsLoggedIn(true);
      // this.setIsAuthed(false);
      const params = {
        lang: getLang()
      };
      const res = await getNuChatAuthApi(params);
      if (!res.meta.success) {
        //get nuchat auth failure
        console.log(`[USERSERVICE.verifyUser] Get NuChat authorization failure. Message: ${res.meta.message}`);
        if (res.meta.message == "LOGIN_ERROR" || res.meta.message == "TOKEN_INVALID") {
          removeUserSession();
          this.setIsAuthed(false);
        }
      } else {
        //get nuchat auth success
        console.log(`[USERSERVICE.verifyUser] Get NuChat authorization success.`);
        setAuthSession(res.data.auth, res.data.user, res.data.user.lang);
        this.setIsLoggedIn(true);
        this.setIsAuthed(true);
        result = true;
      }
    } catch (error) {
      console.log(`[USERSERVICE.verifyUser] Get NuChat authorization failure.`);
    }
    return result;
  }

  //user login
  async login(username, password, captchaToken, isCaptchaOn) {
    let result = {
      success: false,
      message: "",
    };
    const params = {
      email: username,
      password: password,
      lang: getLang(),
      captchaToken: captchaToken,
      isCaptchaOn: isCaptchaOn
    };
    await loginApi(params).then(res => {
      if (!res.meta.success) {
        result.message = res.meta.message;
        //login failure
        removeUserSession();
        this.setIsLoggedIn(false);
        this.setIsAuthed(false);
        console.log(`[USERSERVICE.login] Login failure.`);
      } else {
        //login success
        this.setIsLoggedIn(true);
        this.setIsAuthed(true);
        console.log(`[USERSERVICE.login] Login success.`);
        setUserSession(res.data.user.token, res.data.user, res.data.auth, res.data.user.lang);
        result.success = true;
      }
    }).catch(error => {
      if (error.response.status === 401) console.log(error.response.meta.message);
      else console.log("[USERSERVICE.login] Something went wrong. Please try again later.");
      this.setIsLoggedIn(false);
      this.setIsAuthed(false);
      result.message = "LOGIN_ERROR";
    });

    console.log('[USERSERVICE.login] Logging in with:', username, password);
    return result;
  }

  // Get user authorization
  async getUserAuth() {
    let result = null;
    try {
      const res = await getNuChatAuthApi();
      if (res.meta.success) {
        //get nuchat auth success
        setAuthSession(res.data.auth, res.data.user, res.data.user.lang);
        result = res.data.auth;
      }
    } catch (error) {
      console.log(`[USERSERVICE.verifyUser] Get NuChat authorization failure.`);
    }
    return result;
  }

  //user logout
  async logout() {
    console.log(`[USERSERVICE.logout] Logging out`);
    clear();
    this.setIsLoggedIn(false);
    this.setIsAuthed(false);
    const socket = getSocket();
    if (socket && socket.connected == true) {
      socket.disconnect();
    }
  };
}

export default new UserService();