import React, { useState, useEffect } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
// import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { useTranslation } from "react-i18next";
import { logEvent } from "firebase/analytics";

import { analytics } from "./firebaseAnalytics";
import { getUserId } from './currUser';
import { msgPoppedUpApi } from "../services/nuchatApi";

/**
 * The component is used to pop up a message
 * @param {string} popupMsg.title the dialog title
 * @param {string} popupMsg.message the message content (large font)
 * @param {string} popupMsg.message_2 the message content (small font)
 * @param {string} popupMsg.buttonType the type of button (none / link)
 * @param {string} popupMsg.buttonText the text of the button
 * @param {string} popupMsg.buttonLink the link of the button
 * @param {function} setPopupMsg the function to set the popup message
 * @author Vivian
 * @date 2024-06-03
 */

export default function PopupMsgDialog({ popupMsg, setPopupMsg }) {

  const [openDialog, setOpenDialog] = useState(false); //if the dialog is open
  const [showOperateButton, setShowOperateButton] = useState(false); //if showing the operation button
  const { t } = useTranslation(); //for showing different language text

  useEffect(() => {
    if (!popupMsg || Object.keys(popupMsg).length === 0) {
      setOpenDialog(false);
    } else {
      //error dialog with both "buy now" button and "cancel" botton
      setShowOperateButton(popupMsg.buttonType === "none" ? false : true);
      setOpenDialog(true);
    }
  }, [popupMsg]);

  // handle operation button click
  const handleClickButton = async () => {
    logEvent(analytics, 'NuCHAT_ByNow_Click', { userId: getUserId().toString() }); //record event on firebase
    window.open(popupMsg.buttonLink, '_blank');
  }

  //Close the dialog box
  const handleClose = () => {
    setOpenDialog(false);
    setPopupMsg({});
    msgPoppedUpApi(popupMsg.id);
  }

  return (
    <Dialog
      style={{ width: "100%" }}
      open={openDialog}
      onClose={handleClose}
    >
      {popupMsg.title ?
        <DialogTitle style={{
          display: 'flex',
          alignItems: 'flex-start',
          flexDirection: 'column',
          paddingLeft: 20,
          height: "20px",
          backgroundColor: "rgba(224, 203, 226, 0.5)",
          color: "#513695",
        }}>
          {popupMsg.title}
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        : null}
      <DialogContent sx={{ width: '400px' }}>
        {!popupMsg.title ?
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
          : null}
        <DialogContentText style={{ paddingTop: 20, paddingBottom: 15, paddingLeft:15, paddingRight:15, textAlign: 'center' }}>
          <div><span dangerouslySetInnerHTML={{ __html: popupMsg.message_2 }} /></div>
          {showOperateButton ?
            <Button style={{ fontWeight: "bold", backgroundColor: "#f0dbf2", color: "#513695", marginTop: "5px", textTransform: "none" }} onClick={handleClickButton}>
              {popupMsg.buttonText}
            </Button>
            : null}
        </DialogContentText>
      </DialogContent>
      {/* <DialogActions style={{ justifyContent: 'center' }}>
        {showOperateButton ? <Button style={{fontWeight:"bold", backgroundColor:"#f0dbf2", color:"#513695", marginTop: "0px", marginBottom: "15px", marginLeft:"0px", marginRight: "15px"}} onClick={handleClickButton}>
        <ShoppingCartIcon className="menu-icon" /> {popupMsg.buttonText}
        </Button> : null}
      </DialogActions> */}
    </Dialog>
  );
}
