import "../assets/styles/chat.css";

import React, { useEffect, useState } from 'react';
import { useTranslation } from "react-i18next";
import { Button, Menu, MenuItem, Tooltip } from "@mui/material";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";

import { setLang, getLang } from '../utils/currUser';
import { getSocket } from '../socket';
import Config from "../configs/config.json";

/**
 * the language selector for user change their language
 * @author Vivian
 * @date 2023-9-5
 */
const LanguageSelector = ({color, currLangCode, handleChangeLang}) => {
  
  // let currLangText = Config.languages[currLangCode]; //current language name
  const [langAnchor, setLangAnchor] = useState(null);
  const [currLangText, setCurrLangText] = useState(Config.languages[currLangCode]);

  const { t } = useTranslation(); //for showing different language text
  const socket = getSocket();

  useEffect(() => {
    setCurrLangText(Config.languages[currLangCode]);
  },[currLangCode])

  //open the language list
  const handleLangClick = (event) => {
    setLangAnchor(event.currentTarget);
  };

  //close the language list
  const handleLangClose = () => {
    setLangAnchor(null);
  };

  //handle user select a language
  function handleLangSelect(langCode) {
    handleChangeLang(langCode);
    setCurrLangText(Config.languages[langCode]);
    setLangAnchor(null);
  }

  return (
    <div
      className="language-selector"
      style={{
        height: "fit-content",
      }}
    >
      {/* showing the current language */}
      <Tooltip title={`${t("language_selector.tooltip")}`}>
        <Button
          aria-controls="language-menu"
          aria-haspopup="true"
          onClick={handleLangClick}
        >
          <span id={`language-dropdown-${color}`}>{currLangText}</span>
          <ArrowDropDownIcon className={`right-adornment-${color}`} />
        </Button>
      </Tooltip>

      {/* the language list */}
      <Menu
        id="language-menu"
        anchorEl={langAnchor}
        keepMounted
        open={Boolean(langAnchor)}
        onClose={handleLangClose}
      >
        {Object.entries(Config.languages).map(
          ([langCode, langText]) => {
            return (
              <MenuItem
                key={langCode}
                value={langCode}
                onClick={() => handleLangSelect(langCode)}
                style={{ whiteSpace: "nowrap" }}
              >
                {langText}
              </MenuItem>
            );
          }
        )}
      </Menu>
    </div>
  );
};

export default LanguageSelector;
