
import "../assets/styles/chat.css";

import React from 'react';
import { getLang } from "../utils/currUser";
import FileMessage from "./FileMessage";

/**
 * message bubble - for showing the content of a message
 * @author Vivian
 * @date 2023-9-5
 */
function MessageBubble({ msgId, content, isSelf, msgType, fileInfo }) {
  return (
    <div
      style={{
        overflow: "auto",
        width: "100%",
        wordWrap: "break-word",
      }}
    >
      <div
        style={{
          backgroundColor: msgType === "file" ? "white" : (isSelf ? "#F0B354" : "#A24D84"),
          borderRadius: msgType === "file" ? 0 : 20,
          marginTop: 1,
          marginRight: "auto",
          marginBottom: 1,
          marginLeft: "auto",
          maxWidth: "60%",
          padding: msgType === "file" ? 0 :20,
          // paddingTop: msgType === "file" ? 1 :20,
          // paddingBottom: msgType === "file" ? 1 :20,
          // paddingLeft: msgType === "file" ? 1 :20,
          // paddingRight: msgType === "file" ? 1 :20,
          width: "-webkit-fit-content",
          float: isSelf ? "right" : "left",
          justifySelf: isSelf ? "right" : "left",
        }}
      >
        <p className={
            (getLang() === "ar" || getLang() === "fa")
          ? "direction-rtl"
          : ""
          }
          style={{
            color: isSelf ? "#666666" : "#FFFFFF",
            fontSize: 15,
            fontWeight: "normal",
            margin: 0,
          }}
        >
          {msgType === "file" && fileInfo ? 
          <FileMessage msgId={msgId} fileInfo={fileInfo}/> : content }
        </p>
      </div>
    </div>
  );
}

export default MessageBubble;

