import "../../assets/styles/chat.css"

import React, { useEffect }from 'react';
import { useTranslation } from "react-i18next";
import { logEvent } from "firebase/analytics";
import { TextField } from "@mui/material";
import Button from "@mui/material/Button";
import { useForm, Controller } from 'react-hook-form';
import { ThemeProvider } from '@mui/material/styles';
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";

import { analytics } from "../../utils/firebaseAnalytics";
import { getSocket } from "../../socket";
import DialogWindowTemplate from "../../utils/DialogWindowTemplate";
import customTheme from "../../utils/customTheme";
import { getUserId } from '../../utils/currUser';

/**
 * create group dialog
 * @param {function} props.onClose handle close the dialog
 * @param {Boolean} props.open the dialog is open?
 * @author Vivian
 * @date 2023-11-21
 */
export default function CreateGroupDialog(props) {
  const socket = getSocket();
  const { t } = useTranslation();  //for showing different language text

  const { handleSubmit, control, setError, clearErrors, setValue, formState: { errors } } = useForm();

  useEffect(() => {
    clearErrors('groupName');
    setValue('groupName', '');
  }, [props.open]);

  const onSubmit = async (data) => {
    if (!data.groupName) {
      setError('groupName', { type: 'manual', message: t('group.name.required') });
      return;
    }

    logEvent(analytics, "NuCHAT_Group_Create_Submit", { userId: getUserId().toString()}); //record event on firebase
    socket.emit("create_group", { groupName: data.groupName });
  };

  const onClose = () => {
    clearErrors('groupName');
    setValue('groupName', '');
    props.onClose();
  }

  return (
    <DialogWindowTemplate
      title={t("group.add_group")}
      onClose={onClose}
      open={props.open}
    >
      <div>
        <form onSubmit={handleSubmit(onSubmit)}>

          <DialogContent>
            <div>
              <Controller
                name="groupName"
                control={control}
                rules={{ required: t('group.name.required') }}
                render={({ field }) => (

                  <ThemeProvider theme={customTheme}>
                    {/* group name */}
                    <TextField
                      {...field}
                      label={t('group.name')}
                      variant="outlined"
                      error={Boolean(errors.groupName)}
                      helperText={errors.groupName?.message}
                      style={{ width: '300px' }}
                      autoFocus
                    />
                  </ThemeProvider>

                )}
              />
            </div>
          </DialogContent>
          <DialogActions>
            <Button style={{ fontWeight: "bolder", color: "#513695" }} onClick={onClose}>
              {t('button.cancel')}
            </Button>
            <Button type="submit" style={{ fontWeight: "bolder", backgroundColor: "#f0dbf2", color: "#513695" }} >
              {t('group.add_group.submit')}
            </Button>
          </DialogActions>
        </form>
      </div>
    </DialogWindowTemplate>
  );
}
