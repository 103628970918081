import React from 'react';
import { useTranslation } from 'react-i18next';

const NotAuth = () => {
  const { t } = useTranslation(); //for showing different language text

  return (
    <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", height: "100vh", flexWrap: "wrap" }}>
      <div className='error_title'>{t('loading_title')} </div>
      <img className="error_img" src="/images/loading.png" />
      <div className='error_text'>{t('loading_info')}</div>
    </div>
  );
}

export default NotAuth;