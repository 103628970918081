//This component is based on MUI document.
import * as React from "react";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import DriveFileRenameOutlineIcon from '@mui/icons-material/DriveFileRenameOutline';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

//Dialog template
/**
 * Dialog template
 * @param {function} props.onClose handle close the dialog
 * @param {Boolean} props.open the dialog is open?
 * @param {String} props.title title of the dialog
 * @param {Boolean} props.showEditTitle is showing the edit icon on dialog title
 * @param {function} props.onClickEditTitle handle click the edit icon
 * @param {String} props.subTitle sub-title of the dialog
 */
export default function DialogWindowTemplate(props) {

  return (
    <div>
      <BootstrapDialog
        onClose={props.onClose}
        open={props.open}
      >
        <DialogTitle 
        style={{
          display: 'flex', 
          alignItems: 'flex-start', 
          flexDirection: 'column',
          paddingLeft: 20, 
          backgroundColor: "rgba(224, 203, 226, 0.5)", 
          color: "#513695", 
          }}>
          <div style={{ margin:"0px", padding:"0px", display: 'flex', alignItems: 'center', fontWeight: "bold" }}>
          {props.title}
          {props.showEditTitle? <DriveFileRenameOutlineIcon style={{paddingLeft:"15px"}} onClick={props.onClickEditTitle}/> : null}
          </div>
          {props.subTitle ? <div style={{margin:"0px", padding:"0px", fontSize:"10px"}}>
            {props.subTitle}
          </div> : null}
        </DialogTitle>
        
        <IconButton
          aria-label="close"
          onClick={props.onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent dividers>
          <strong>{props.children}</strong>
        </DialogContent>
      </BootstrapDialog>
    </div>
  );
}
