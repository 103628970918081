import i18next from 'i18next';
import { set } from 'react-hook-form';
import Config from '../configs/config.json';

// return the user id from the local storage
export const getUserId = () => {
  const userId = localStorage.getItem('userId');
  if (userId) return parseInt(userId);
  else return null;
}

// return the user from the local storage
export const getUser = () => {
  const user = localStorage.getItem('user');
  if (user) return JSON.parse(user);
  else return null;
}

// return the token from the local storage
export const getToken = () => {
  return localStorage.getItem('token') || null;
}

// return the user nuchat authorization from the local storage
export const getAuth = () => {
  const nuChatAuth = localStorage.getItem('nuChatAuth');
  if (nuChatAuth) return JSON.parse(nuChatAuth);
  else return null;
}

// return the user nuchat authorization from server
// export const getUpdatedAuth = async () => {
//   const nuChatAuth = await getNuChatAuthApi();
//   if (nuChatAuth.meta.success){
//     localStorage.setItem('nuChatAuth', JSON.stringify(nuChatAuth.data.auth));
//     return nuChatAuth.data.auth;
//   } 
//   else return null;
// }

// return the language from i18n
export const getLang = () => {
  return i18next.language;
}

// set the language from the i18n
export const setLang = (lang) => {
  i18next.changeLanguage(lang);
  localStorage.setItem('lang', lang);
}

// return the current chat session
export const getCurrSessionId = () => {
  return localStorage.getItem('currSessionId') || null;
}

// set the current chat session
export const setCurrSessionId = (chatSessionId) => {
  localStorage.setItem('currSessionId', chatSessionId);
}

export const getLeftLang = () => {
  return localStorage.getItem('inpersonLeftLang') || null;
}

export const setLeftLang = (lang) => {
  localStorage.setItem('inpersonLeftLang', lang);
}

export const getRightLang = () => {
  return localStorage.getItem('inpersonRightLang') || null;
}

export const setRightLang = (lang) => {
  localStorage.setItem('inpersonRightLang', lang);
}

// init or clear the in-person conversation information
export const initInpersonSession = () => {
  //init the inpersonSession
  const inpersonSession = {
    _id: 'in-person-conversation',
    type: "friend",
    groupName: "In-person conversation",
    groupAvatar: "",
    members: [0, getUserId()],
    messages: [],
  };
  localStorage.setItem('inpersonSession', JSON.stringify(inpersonSession));

  //init the left language, default is English
  if(!getLeftLang()){
    setLeftLang("en");
  }
  //init the right language, default is same with current user's language
  if(!getRightLang()){
    setRightLang(getLang());
  }
}

// return the in-person conversation information, if couldn't find it in localstorage then init it.
export const getInpersonSession = () => {
  let inpersonSession = localStorage.getItem('inpersonSession');
  if(!inpersonSession){
    initInpersonSession();
    inpersonSession = localStorage.getItem('inpersonSession');
  }
  if (inpersonSession) return JSON.parse(inpersonSession);
  else return null;
}

// save new message in the in-person conversation
export const addInpersonMsg = (message) => {
  let inpersonSession = getInpersonSession();
  if(inpersonSession && inpersonSession.messages){
    inpersonSession.messages = [...inpersonSession.messages, message];
  }
  localStorage.setItem('inpersonSession', JSON.stringify(inpersonSession));
}

// update message in in-person conversation
export const updateInpersonMsg = (message) => {
  let inpersonSession = getInpersonSession();
  if(inpersonSession && inpersonSession.messages){
    const updatedMsgs = inpersonSession.messages.map(msg => {
      if (msg._id === message._id) {
        return message;
      } else {
        return msg;
      }
    });
    inpersonSession.messages = updatedMsgs;
    localStorage.setItem('inpersonSession', JSON.stringify(inpersonSession));
  }
}

// set the token, user id, user, NuChat authorizaiton from the local storage
export const setUserSession = (token, user, nuChatAuth, lang) => {
  localStorage.setItem('token', token);
  localStorage.setItem('userId', user.id.toString());
  localStorage.setItem('user', JSON.stringify(user));
  localStorage.setItem('nuChatAuth', JSON.stringify(nuChatAuth));
  i18next.changeLanguage(lang);
}

// set the NuChat authorizaiton from the local storage
export const setAuthSession = (nuChatAuth, user, lang) => {
  localStorage.setItem('userId', user.id.toString());
  localStorage.setItem('user', JSON.stringify(user));
  localStorage.setItem('nuChatAuth', JSON.stringify(nuChatAuth));
  i18next.changeLanguage(lang);
}

// remove the token, user id and NuChat authorization from the local storage
export const removeUserSession = () => {
  localStorage.removeItem('token');
  localStorage.removeItem('userId');
  localStorage.removeItem('user');
  localStorage.removeItem('nuChatAuth');
}

// remove the NuChat authorization from the local storage
export const removeAuthSession = () => {
  localStorage.removeItem('user');
  localStorage.removeItem('nuChatAuth');
}

// set the settings in the local storage
export const setSettings = (configsFormServer) => {
  localStorage.setItem('settings_paymentUrl', configsFormServer.links.paymentUrl);
  localStorage.setItem('settings_allowedMimeTypes', configsFormServer.settings.allowedMimeTypes);
  localStorage.setItem('settings_maxFileSize', configsFormServer.settings.maxFileSize);
  localStorage.setItem('settings_msgLimitChars', configsFormServer.settings.msgLimitChars);
}

// get the settings from the local storage
export const getSettings = () => {
  const settings = {
    paymentUrl: localStorage.getItem('settings_paymentUrl') || Config.website.paymentUrl,
    allowedMimeTypes: localStorage.getItem('settings_allowedMimeTypes') || Config.upload_file_types,
    maxFileSize: localStorage.getItem('settings_maxFileSize') || Config.upload_file_max_size,
    msgLimitChars: localStorage.getItem('settings_msgLimitChars') || 200,
  };
  return settings;
}

// clear localStorage
export const clear = () => {
  localStorage.clear();
}