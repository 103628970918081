import "../../assets/styles/chat.css"

import React, { useEffect } from 'react';
import { useTranslation } from "react-i18next";
import { useForm, Controller } from 'react-hook-form';
import { ThemeProvider } from '@mui/material/styles';
import { TextField } from "@mui/material";
import Button from "@mui/material/Button";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import { logEvent } from "firebase/analytics";

import { analytics } from "../../utils/firebaseAnalytics";
import { getSocket } from "../../socket";
import DialogWindowTemplate from "../../utils/DialogWindowTemplate";
import customTheme from "../../utils/customTheme";

/**
 * Add group member dialog
 * @param {function} props.onClose handle close the dialog
 * @param {Boolean} props.open the dialog is open?
 * @param {string} props.groupId the group id
 * @author Vivian
 * @date 2023-11-21
 */
export default function AddMemberDialog(props) {
  const socket = getSocket();
  const { t } = useTranslation();  //for showing different language text

  const { handleSubmit, control, setError, clearErrors, setValue, formState: { errors } } = useForm();

  useEffect(() => {
    clearErrors('memberInfo');
    setValue('memberInfo', '');
  }, [props.open]);

  const onSubmit = async (data) => {
    if (!data.memberInfo) {
      setError('memberInfo', { type: 'manual', message: t('user.search_info.required') });
      return;
    }

    // logEvent(analytics, "add_group_memeber", { memberInfo: data.memberInfo });
    const memberInfo = {
      searchTx: data.memberInfo,
      groupId: props.groupId
    }
    socket.emit("add_group_member", memberInfo);
  };

  const onClose = () => {
    clearErrors('memberInfo');
    setValue('memberInfo', '');
    props.onClose();
  }

  return (
    <DialogWindowTemplate
      title={t("group.add_member")}
      onClose={onClose}
      open={props.open}
    >
      <div>
        <form onSubmit={handleSubmit(onSubmit)}>

          <DialogContent>
            <div>
              <Controller
                name="memberInfo"
                control={control}
                rules={{ required: t('user.search_info.required') }}
                render={({ field }) => (

                  <ThemeProvider theme={customTheme}>
                    {/* member info */}
                    <TextField
                      {...field}
                      label={t('user.search_info')}
                      variant="outlined"
                      error={Boolean(errors.memberInfo)}
                      helperText={errors.memberInfo?.message}
                      style={{ width: '300px' }}
                      autoFocus
                    />
                  </ThemeProvider>

                )}
              />
            </div>
          </DialogContent>
          <DialogActions>
            <Button style={{ fontWeight: "bolder", color: "#513695" }} onClick={onClose}>
              {t('button.cancel')}
            </Button>
            <Button type="submit" style={{ fontWeight: "bolder", backgroundColor: "#f0dbf2", color: "#513695" }} >
              {t('group.add_member.submit')}
            </Button>
          </DialogActions>
        </form>
      </div>
    </DialogWindowTemplate>
  );
}
